import {
  Badge,
    Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { FormatToLocalCurrency } from "../../../app/utils/currency/formatToLocalCurrency";
import Iconify from "../../../app/iconify";
import { useEffect, useRef, useState } from "react";
import { useCurrentBreakpoint, useResponsiveFontSize } from "../../../app/hooks/use-responsive-font";
import { makeStyles } from "@mui/styles";
import { OrganizationData } from "../../../app/interfaces/organizationData";

// ----------------------------------------------------------------------

type Props = {
  orgData: OrganizationData;
  item: any;
  index: number;
  selectedItems: any[];
  // selected: boolean;
  // onSelectRow: VoidFunction;
  // onViewRow: VoidFunction;
  // onEditRow: VoidFunction;
  handleUpdateSelectedItem: (index: number, item: any) => void;
  onDeleteRow: (index: number) => void;
};

const useStyles = makeStyles({
  input: {
    '& input[type=number]': {
        '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    }
  },
});

export default function DamageWaiverRow({orgData, item, index, selectedItems,  handleUpdateSelectedItem, onDeleteRow }: Props) {
  
  const responseiveFontSize = useResponsiveFontSize();
  const theme = useTheme();
  const classes = useStyles();

  const {
    currentBreakpoint,
    isSMAndDown,
    isMDAndDown,
    isMDAndUp,
  } = useCurrentBreakpoint();
  
  const rateRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLInputElement>(null);

  const [damageWaiverPercentage, setDamageWaiverPercentage] = useState(item?.damageWaiverPercentage || (orgData.defaultDamageWaiverPercent || 10));
  const [itemDescription, setItemDescription] = useState(item?.selectedDescription || "");
  const [taxable, setTaxable] = useState(item?.selectedTaxable || false);
  const [subtotal, setSubtotal] = useState(item?.rowTotal || 0);
  const [includedItems, setIncludedItems] = useState<any[]>([]);

  const calculateSectionTotal = () => {

    // Calculate the subtotal for the section.
    // A damage waiver is a percentage fee applied to the inventory items above it.
    // Should ignore any NON inventory items.
  
    let total = 0;
    let foundSubtotal = false;
    let localIncludedItems: any[] = [];

    for (let i = item.listIndex - 1; i >= 0 && !foundSubtotal; i--) {
      const currentItem = selectedItems[i];

      if (currentItem.rowType === "subtotal" || currentItem.rowType === "percentFee") {
        continue;
      }
      if (currentItem.rowType === "damageWaiver") {
        foundSubtotal = true;
        break;
      }

      if (currentItem.rowType === "singleItem") {
        total += currentItem.rowTotal;
        localIncludedItems.push(currentItem);
      }
    }

    // Update the subtotal state only once per function call
    setSubtotal(total * (damageWaiverPercentage / 100));
    setIncludedItems(localIncludedItems);
};

useEffect(() => {
  updateItem();
}, [subtotal]);

// recalculate the section total whenever selectedItems or item.index changes
useEffect(() => {
    if (selectedItems.length === 0) {
      return;
    }
    calculateSectionTotal();
}, [selectedItems, damageWaiverPercentage]); 

const updateItem = () => {
  item.selectedDescription = itemDescription;
  item.selectedTaxable = taxable;
  item.rowTotal = subtotal;
  item.damageWaiverPercentage = damageWaiverPercentage;

  handleUpdateSelectedItem(item.listIndex, item);
};

useEffect(() => {
  updateItem();
}
, [taxable]);

  return (
    <Box
      sx={{
        p: 1,
        borderRadius: 1,
        backgroundColor: alpha(theme.palette.warning.lighter, 0.4),
      }}
    >
      <Grid container spacing={{ xs: 2, lg: 1, xl: 2 }} alignItems="center">
        <Grid item xs={6} lg={3}>
          <Stack direction={"row"} gap={1} alignItems={"center"}>
          <Badge badgeContent={index + 1} color="primary" sx={{mr: 2}}>
              <DragIndicatorIcon />
            </Badge>
            <Typography fontSize={responseiveFontSize}>
              Damage Waiver
            </Typography>
          </Stack>
        </Grid>

        <Grid item xs={6} md={3} lg={3.5}>
          <TextField
            inputRef={descriptionRef}
            fullWidth
            inputProps={{ style: { fontSize: responseiveFontSize } }}
            InputLabelProps={{ style: { fontSize: responseiveFontSize } }}
            size="small"
            label="Description"
            variant="outlined"
            value={itemDescription}
            onChange={(e) => setItemDescription(e.target.value)}
            onBlur={updateItem}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (descriptionRef.current) {
                  descriptionRef.current.blur()
                }
              }
            }}
          />
        </Grid>

        {
          isMDAndUp && (
            <Grid item md={1.5} lg={2.5}/>
          ) 
        }

        <Grid item xs={6} md={3} lg={1}>
          <TextField
            inputRef={rateRef}
            className={classes.input}
            inputProps={{ style: { fontSize: responseiveFontSize } }}
            InputLabelProps={{ style: { fontSize: responseiveFontSize } }}
            size="small"
            label="Rate"
            type="number"
            variant="outlined"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            value={damageWaiverPercentage === 0 ? "" : damageWaiverPercentage}
            onChange={(e) => setDamageWaiverPercentage(Number(e.target.value))}
            // onBlur={updateItem}
            onKeyDown={(e) => {
              console.log(e.key);
              if (e.key === "Enter") {
                if (rateRef.current) {
                  rateRef.current.blur()
                }
              }
            }}
          />
        </Grid>
        
         {/* //MARK: - TAXABLE */}
         <Grid item xs={12} sm={6} md={4} lg={1}>
          <FormControlLabel
            control={
              <Checkbox
                inputProps={{ style: { fontSize: responseiveFontSize } }}
                checked={taxable}
                onChange={(e) => setTaxable(e.target.checked)}
              />
            }
            label={
              <Typography fontSize={responseiveFontSize}>
                {isMDAndDown ? "Taxable" : "Taxed"}
              </Typography>
            }
          />
        </Grid>
       

        <Grid item xs={0.5}>
          <Tooltip title={
           <Typography component="div">
           Applied to:
           {includedItems.map((item, index) => (
             <div key={index}>{item.name}</div>
           ))}
         </Typography>
          }>
          <Typography fontSize={responseiveFontSize}>
            {FormatToLocalCurrency(subtotal)}
          </Typography>
          </Tooltip>
        </Grid>

        <Grid item xs={0.5}>
          <Button
            size="small"
            color="error"
            startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
            onClick={() => onDeleteRow(item.listIndex)}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
