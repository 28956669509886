import {
  Badge,
    Box,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
  alpha,

  useTheme,
} from "@mui/material";
import { makeStyles } from '@mui/styles';

import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { FormatToLocalCurrency } from "../../../app/utils/currency/formatToLocalCurrency";
import Iconify from "../../../app/iconify";
import { useEffect, useRef, useState } from "react";
import { Breakpoints, useCurrentBreakpoint, useResponsiveFontSize } from "../../../app/hooks/use-responsive-font";

import { use } from "i18next";

// ----------------------------------------------------------------------

type Props = {
  item: any;
  index: number;
  selectedItems: any[];

  // selected: boolean;
  // onSelectRow: VoidFunction;
  // onViewRow: VoidFunction;
  // onEditRow: VoidFunction;
  handleUpdateSelectedItem: (index: number, item: any) => void;
  onDeleteRow: (index: number) => void;
};

const useStyles = makeStyles({
  input: {
    '& input[type=number]': {
        '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    }
  },
});

export default function SubtotalRow({ item, index, selectedItems, handleUpdateSelectedItem, onDeleteRow }: Props) {

  // Text Field Refs
  const descriptionRef = useRef<HTMLInputElement>(null);
  
  const responseiveFontSize = useResponsiveFontSize();
  const classes = useStyles();

  const theme = useTheme();

  const {
    currentBreakpoint,
    isSMAndDown,
    isMDAndDown,
    isMDAndUp,
  } = useCurrentBreakpoint();

  const [itemDescription, setItemDescription] = useState(item?.selectedDescription || "");
  const [subtotal, setSubtotal] = useState(item?.rowTotal || 0);

  const calculateSectionTotal = () => {
    let total = 0;
    let foundSubtotal = false;

    for (let i = item.listIndex - 1; i >= 0 && !foundSubtotal; i--) {
        const currentItem = selectedItems[i];
        if (currentItem.rowType === "subtotal") {
            foundSubtotal = true;
            break;
        }
        total += currentItem.rowTotal;
      }
      
      console.log(`subtotal: ${total}`);
    // Update the subtotal state only once per function call
    setSubtotal(total);
};

const updateItem = () => {
  item.selectedDescription = itemDescription;
  item.rowTotal = subtotal;
  handleUpdateSelectedItem(item.listIndex, item);
};

useEffect(() => {
  updateItem();
}, [subtotal]);

// recalculate the section total whenever selectedItems or item.index changes
useEffect(() => {
    if (selectedItems.length === 0) {
      return;
    }
    calculateSectionTotal();
}, [selectedItems]); 


  return (
    <Box
      sx={{
        py: 1,
        pl: 1,
        pr: currentBreakpoint === Breakpoints.LG ? 1 : 0,
        alignContent: "center",
        borderRadius: 1,
        backgroundColor: alpha(theme.palette.primary.lighter, 0.4),
      }}
    >
      <Grid container spacing={{ xs: 2, lg: 1, xl: 1 }} alignItems="center">
        <Grid item xs={6} lg={3}>
          <Stack direction={"row"} gap={1} alignItems={"center"}>
          <Badge badgeContent={index + 1} color="primary" sx={{mr: 2}}>
              <DragIndicatorIcon />
            </Badge>
            <Typography fontSize={responseiveFontSize}>Subtotal</Typography>
          </Stack>
        </Grid>

        <Grid item xs={6} md={3} lg={3.5} >
        <TextField
            inputRef={descriptionRef}
            fullWidth
            inputProps={{ style: { 
              fontSize: responseiveFontSize 
            } }}
            InputLabelProps={{ style: { fontSize: responseiveFontSize } }}
            size="small"
            label="Description"
            variant="outlined"
            value={itemDescription}
            onChange={(e) => setItemDescription(e.target.value)}
            onBlur={updateItem}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (descriptionRef.current) {
                  descriptionRef.current.blur()
                }
              }
            }}
          />
        </Grid>

        {
          isMDAndUp && (
            <Grid item md={1.5} lg={4.5}/>
          ) 
        }

        <Grid item xs={6} md={0.5}>
          <Typography fontSize={responseiveFontSize}>
            {FormatToLocalCurrency(subtotal)}
          </Typography>
        </Grid>

        <Grid item xs={6} md={0.5}>
        <Box
            display="flex"
            justifyContent={isSMAndDown ? "flex-end" : "flex-start"}
            // width="100%"
          >
            <Button
              size="small"
              color="error"
              startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
              onClick={() => onDeleteRow(item.index)}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
