import { useFirestore } from "react-redux-firebase";
import { useEffect, useState } from "react";
import moment from "moment";
import {
  useGetCategoriesQuery,
  useGetInvoicesForRangeIntersectQuery,
} from "../api";
import { sortByNumberValue } from "../../utils/sorters/sortBy";
import { GetUsageByInventory } from "./helpers/reports/getUsageByInventory";
import { GetOverbookedInventoryForRange } from "./helpers/reports/getOverbookedInventoryForRange";
import { useSelector } from "react-redux";
import { authSelector } from "../../../domains/auth/authSlice";
import { useFirestoreContext } from "../../global-context/FirestoreContext";

export function useUsageReport() {

  const { inventory } = useFirestoreContext();

  const firestore = useFirestore();
  const { fsOrgPrefix, orgData } = useSelector(authSelector);
  const [dateRange, setDateRange] = useState({
    start: moment().subtract(6, "days").startOf("day"),
    end: moment().endOf("day"),
  });
  const [loading, setLoading] = useState(false);
  const [itemFilters, setItemFilters] = useState([]);
  const [viewIntervalRange, setViewIntervalRange] = useState("day");
  const [chartData, setChartData] = useState([]);
  const [chartSort, setChartSort] = useState("usage");
  const [overbookedData, setOverbookedData] = useState([]);
  // const [inventory, setInventory] = useState([]);
  const [selectedInvItem, setSelectedInvItem] = useState(null);
  const [selectedInvAmt, setSelectedInvAmt] = useState(0);
  const [filteredInvoices, setFilteredInvoices] = useState([]);

  const {
    data: invoices,
    isFetching: fetchingInvoices,
    refetch: refetchInvoices,
  } = useGetInvoicesForRangeIntersectQuery({
    db: { firestore },
    orgPrefix: fsOrgPrefix,
    dateStart: dateRange.start ?? null,
    dateEnd: dateRange.end ?? null,
    orgTimezone: orgData?.orgTimezone,
    useTurnaround: false,
    invoiceOnly: true,
  });

  const { data: categories, isFetching: fetchingCategories } =
    useGetCategoriesQuery({
      db: { firestore },
      orgPrefix: fsOrgPrefix,
    });


  useEffect(() => {
    refetchInvoices();
  }, [dateRange]);


  useEffect(() => {
    if (fetchingInvoices || fetchingCategories)
      return setLoading(true);
    setLoading(false);
    // format for usage data
    // run through filters
    const invList = GetUsageByInventory({
      invoices,
      inventoryIds: inventory?.map((i) => i?.id),
      inventory,
      dateRange,
      chartSort,
      filterItemIds: itemFilters,
    });

    setChartData(invList);
    const filteredInvIds = invList?.map((i) => i?.inventoryId);
    const filterInvoices = ({ inventoryIds, invoices }) => {
      return invoices?.filter((i) =>
        i?.selectedItemsQueryHook?.map((id) => inventoryIds.includes(id))
      );
    };

    const filteredInvoices = filterInvoices({
      inventoryIds: filteredInvIds,
      invoices,
    });

    const filteredSelected = filteredInvoices?.filter((inv) => {
      return !!inv?.selectedItemsQueryHook.includes(selectedInvItem);
    });

    setFilteredInvoices(
      selectedInvItem
        ? sortByNumberValue(filteredSelected, "invoiceNumber")
        : sortByNumberValue(filteredInvoices, "invoiceNumber")
    );

    const reduced = GetOverbookedInventoryForRange({
      startDate: dateRange.start,
      endDate: dateRange.end,
      inventory,
      invoicesInRange: invoices,
      useTurnaround: false,
    });

    setOverbookedData(reduced);
  }, [
    invoices,
    categories,
    inventory,
    itemFilters,
    selectedInvItem,
    chartSort,
  ]);

  useEffect(() => {
    if (!selectedInvItem) return setSelectedInvAmt(0);
    const amt = chartData?.find((i) => i?.inventoryId === selectedInvItem);
    setSelectedInvAmt(amt?.filteredTotal);
  }, [selectedInvItem, chartData]);

  const changeDateRange = (dates) => {
    setDateRange({
      start: dates?.[0]?.startOf("day"),
      end: dates?.[1]?.endOf("day"),
    });
  };

  const changeItemFilters = (filters) => {
    setSelectedInvItem(null);
    setItemFilters([...filters]);
  };

  const changeSelectedInvItem = (v) => {
    setSelectedInvItem(v);
  };

  const changeChartSort = (v) => {
    setChartSort(v);
  };

  return {
    state: {
      dateRange,
      viewIntervalRange,
      categories,
      // fetchingInventory,
      fetchingCategories,
      fetchingInvoices,
      inventory,
      chartData,
      selectedInvItem,
      invoices,
      filteredInvoices,
      selectedInvAmt,
      itemFilters,
      chartSort,
      overbookedData,
      loading,
    },
    actions: {
      changeDateRange,
      changeItemFilters,
      changeSelectedInvItem,
      setDateRange,
      setViewIntervalRange,
      changeChartSort,
    },
  };
}
