import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, CircularProgress, Container, Divider, Stack, Table, Typography } from '@mui/material';
import { useFirestore } from 'react-redux-firebase';
import { OrganizationData } from '../../app/interfaces/organizationData';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { functions } from '../../app/config/firebase';
import { Invoice } from '../../app/interfaces/invoice';
import { checkInvoices } from '../../app/utils/models/checkers/checkInvoices';
// Define the prop type for orgData
interface SyncReportProps {
  orgData: OrganizationData;
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function InvoicesSyncReport({ orgData }: SyncReportProps) {
  const classes = useStyles();

  const firestore = useFirestore();

  const [badInvoices, setBadInvoices] = useState<Invoice[] | null>(null);
  const [loadingInitial, setLoadingInitial] = useState(false);
  const [loadingInitialComplete, setLoadingInitialComplete] = useState(false);

  interface InvoiceStatus {
    processing: boolean;
    success: boolean;
    error: {
      Message: string;
      Detail: string;
      Code: string;
    } | null;
  }

  const [processingStatus, setProcessingStatus] = useState<{
    [invoiceId: string]: InvoiceStatus;
  }>({});

  useEffect(() => {
    if (orgData) {
      setLoadingInitial(true);

      const loadInvoices = async () => {
        const invoicesQuery = await firestore.collection('orgs').doc(orgData.id).collection('invoices').where('type', '==', 'invoice').where('qbId', '==', null).get();

        const invoices = invoicesQuery.docs.map((snap) => checkInvoices(snap, orgData.orgTimezone) as Invoice);

        setBadInvoices(invoices);
        setLoadingInitial(false);
        setLoadingInitialComplete(true);
      };

      loadInvoices();
    }
  }, [orgData]);

  // Function to trigger cloud function for a specific invoice
  const syncInvoiceToQuickBooks = async (index: number) => {
    const invoice = badInvoices && badInvoices[index];
    if (!invoice) {
      return;
    }

    // Update the processing status for this invoice to "Processing"
    setProcessingStatus((prevProcessingStatus) => ({
      ...prevProcessingStatus,
      [invoice.id]: {
        processing: true,
        success: false,
        error: null,
      },
    }));

    // Call your cloud function here for the invoice at the given index
    try {
      // Perform the cloud function call
      const call = functions.httpsCallable('manualInvoiceOnlySync');

      return call({
        orgId: orgData.id,
        invoiceId: invoice.id,
      })
        .then(async (res: any) => {
          console.log(res);
          const status = res.data.status;
          if (status === 200) {
            setProcessingStatus((prevProcessingStatus) => ({
              ...prevProcessingStatus,
              [invoice.id]: {
                processing: false,
                success: true,
                error: null,
              },
            }));
          } else if (status === 500) {
            const errorDataArray = res.data.error.Fault.Error;
            const errorData = errorDataArray[0];

            setProcessingStatus((prevProcessingStatus) => ({
              ...prevProcessingStatus,
              [invoice.id]: {
                processing: false,
                success: false,
                error: errorData || {
                  Message: 'Sync Failed',
                  Detail: 'Sorry, we do not know why it failed. Please contact support.',
                  Code: 'ERROR_CODE',
                },
              },
            }));
          }
        })
        .catch((err: any) => {
          console.log(err);
        });

      // If the cloud function call is successful, update the status to "Success"
    } catch (error) {
      // If there is an error, update the status to "Failure"
    }
  };

  // Sync all invoicess.
  const syncAllInvoices = async () => {
    if (!badInvoices || badInvoices?.length === 0) {
      return;
    }

    const updatedProcessingStatus = { ...processingStatus }; // Create a copy to update

    for (let index = 0; index < badInvoices.length; index++) {
      const invoice = badInvoices[index];

      // Update the processing status for this invoice to indicate processing.
      updatedProcessingStatus[invoice.id] = {
        processing: true,
        success: false,
        error: null,
      };
      setProcessingStatus(updatedProcessingStatus);
    }

    for (let index = 0; index < badInvoices.length; index++) {
      await syncInvoiceToQuickBooks(index);
    }
  };

  return (
    <>
      {loadingInitial && <CircularProgress />}

      {loadingInitialComplete && badInvoices && (
        <>
          <Divider />
          <br />

          <Stack spacing={2} alignItems={'center'} direction={'row'}>
            <Typography color={'darkred'}>You have {badInvoices?.length} invoices that are not synced to your QuickBooks account.</Typography>

            <Button variant='contained' disabled={true} color='primary' onClick={() => syncAllInvoices()}>
              Sync All
            </Button>
          </Stack>

          <br />

          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell>Invoice Number</TableCell>
                  <TableCell align='right'>Customer</TableCell>
                  <TableCell align='right'>Sync</TableCell>
                  <TableCell align='right' sx={{ width: '50%' }}>Results</TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {badInvoices.map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell component='th' scope='row'>
                      {row.invoiceNumber}
                    </TableCell>

                    <TableCell align='right'>{row.customer.customerDisplayName}</TableCell>

                    <TableCell align='right'>
                      {processingStatus[row.id]?.processing ? (
                        <Button disabled>Processing...</Button>
                      ) : processingStatus[row.id]?.success ? (
                        <Button disabled>Synced</Button>
                      ) : (
                        <Button variant='contained' color='primary' onClick={() => syncInvoiceToQuickBooks(index)}>
                          Sync to QuickBooks
                        </Button>
                      )}
                    </TableCell>

                    <TableCell align='right'>
                      {processingStatus[row.id]?.error ? (
                        <Stack direction={'column'}>
                          <Typography color={'darkred'}>{processingStatus[row.id]?.error?.Message}</Typography>
                          <Typography color={'darkred'}>{processingStatus[row.id]?.error?.Detail}</Typography>
                        </Stack>
                      ) : processingStatus[row.id]?.success ? (
                        <Typography color={'darkgreen'}>SUCCESS!</Typography>
                      ) : (
                        <>--</>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}
