import React, { useState, useEffect, useRef } from 'react';
import { UploadProps, RcFile } from 'antd/lib/upload';

import type { RcFile as OriRcFile, UploadProps as RcUploadProps, UploadRequestOption, UploadRequestError } from 'rc-upload/lib/interface';

import { message, Tabs, Button, Drawer, Input, Spin, Row, Col, Space, Upload, Modal, List, Card, Select, Tag } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { MessageOutlined, SendOutlined, InboxOutlined, PlusOutlined, FilePdfOutlined } from '@ant-design/icons';

import { useSelector } from 'react-redux';
import { authSelector } from '../../auth/authSlice';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import MessageItem from './InternalMessage';
import { Spinner } from '../../../app/ui/spinner';
import { Invoice, InternalNotesProps, InternalFile, Media, Message } from '../../../app/interfaces/invoice';
import { firelikeId } from '../../../app/utils/models/checkers/firelikeid';

const { TabPane } = Tabs;
const { Dragger } = Upload;
const { Option } = Select;

const InternalNotes: React.FC<InternalNotesProps> = ({ showTags, visible, handleCloseDrawer, setSelectedTags, invoice, tags, selectedTags }) => {

  const firestore = useFirestore();
  const firebase = useFirebase();

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingFiles, setLoadingFiles] = useState<boolean>(false);

  const [tabType, setTabType] = useState<string>('notes');    // notes / files / tags 
  const [newMessage, setNewMessage] = useState<string>('');
  const [messages, setMessages] = useState<Message[]>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [imageFilesList, setimageFilesList] = useState<UploadFile[]>([]);
  const [fileList, setFileList] = useState<any[]>([]);

  const [downloadUrls, setDownloadUrls] = useState<string[]>([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleChange: UploadProps['onChange'] = ({ fileList: newimageFilesList }) => setimageFilesList(newimageFilesList);

  const handleCancel = () => setPreviewOpen(false);

  const handleAddTag = (value: string | null) => {
    if (!value) { return }
    if (selectedTags.includes(value)) { return }
    const newTags = [...selectedTags, value]
    setSelectedTags(newTags);

    firebase
      .firestore()
      .collection("orgs")
      .doc(invoice.orgId)
      .collection("invoices")
      .doc(invoice.id)
      .update({ tagsQueryHook: newTags });

    setSelectedOption(null);
  };
  const handleRemoveTag = (removedTagId: string) => {

    const newTags = selectedTags.filter(tagId => tagId !== removedTagId)
    setSelectedTags(newTags);

    firebase
      .firestore()
      .collection("orgs")
      .doc(invoice.orgId)
      .collection("invoices")
      .doc(invoice.id)
      .update({ tagsQueryHook: newTags });
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  };

  // Define the upload function
  const handleUploadImage = async (file: RcFile) => {
    try {
      const storageRef = firebase.storage().ref();
      const storagePath = `orgs/${invoice.orgId}/invoices/${invoice.id}/images/${file.name}`;
      const fileRef = storageRef.child(storagePath);
      await fileRef.put(file);

      const downloadUrl = await fileRef.getDownloadURL();
      setDownloadUrls([...downloadUrls, downloadUrl]);
    } catch (error) {
      // Handle error
    }
  };

  const props: UploadProps = {
    name: 'file',
    accept: '.jpg,.jpeg,.png',
    customRequest: async (options: UploadRequestOption) => {
      const { file, onSuccess, onError } = options;
      try {
        // Perform your upload logic here
        const response = await handleUploadImage(file as RcFile);

        onSuccess && onSuccess(response);
      } catch (error) {
        onError && onError(error as UploadRequestError);
      }
    },
    listType: 'picture-card',
    fileList: imageFilesList,
    onPreview: handlePreview,
    onChange: handleChange,
  };

  const messagesEndRef = useRef<HTMLDivElement>(null);

  const { userData } = useSelector(authSelector);

  useEffect(() => {
    const fetchData = async () => {
      if (invoice) {
        const unsubscribe = firebase
          .firestore()
          .collection('orgs')
          .doc(invoice.orgId)
          .collection('invoices')
          .doc(invoice.id)
          .collection('messages')
          .orderBy('timestamp', 'asc')
          .onSnapshot((snapshot) => {
            const data: Message[] = snapshot.docs.map((doc) => {
              const messageData = doc.data() as Message;
              const mediaData = messageData.media?.map((m) => m as Media) || [];
              return {
                ...messageData,
                media: mediaData,
              };
            });
            setMessages(data);
          });

        return () => {
          unsubscribe();
        };
      }
    };

    fetchData();
  }, [firebase, firestore, invoice]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() === '' && imageFilesList.length === 0) return;

    try {
      const messageRef = firebase.firestore().collection('orgs').doc(invoice.orgId).collection('invoices').doc(invoice.id).collection('messages').doc();

      const mediaObjects: Media[] = downloadUrls.map((url) => ({
        id: firelikeId(), // Generate a unique ID for the media object
        isVideo: false, // Set as false for image files
        liked: false, // Set initial like status
        mediaUrl: url, // Use the stored download URL or empty string if unavailable
        nsfwContent: false, // Set initial NSFW status
      }));

      const messageData: Message = {
        body: newMessage,
        id: messageRef.id,
        imageUrls: [],
        media: mediaObjects,
        messageRead: false,
        sendSuccess: true,
        sentByName: userData.firstName,
        timestamp: new Date(),
        userId: userData.id,
        videoUrls: [],
      };

      await messageRef.set(messageData);

      setNewMessage('');
      setimageFilesList([]);
      setDownloadUrls([]);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const onClose = () => {
    setNewMessage('');
    setimageFilesList([]);
    setFileList([]);
    setDownloadUrls([]);

    handleCloseDrawer({
      visible: false,
      invoice: null,
    });
  };

  const handleUploadFile = async (options: any) => {
    const { file, onSuccess, onError, onProgress } = options;

    try {
      const storageRef = firebase.storage().ref();
      const storagePath = `orgs/${invoice.orgId}/invoices/${invoice.id}/files/${file.name}`;
      // const storagePath = `${file.name}`;

      const uploadTask = storageRef.child(storagePath).put(file);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Upload progress
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          onProgress({ percent: progress }, file);
        },
        (error) => {
          // Upload error
          onError(error);
          message.error('File upload failed.');
          console.error('Error uploading file:', error);
        },
        () => {
          // Upload complete
          uploadTask.snapshot.ref
            .getDownloadURL()
            .then(async (downloadUrl) => {
              // Create InvoiceFile object
              const invoiceFile: InternalFile = {
                name: file.name,
                downloadUrl,
                size: Math.round(file.size / 1024),
                uploadedBy: 'John Doe', // Replace with actual user name
                createdOn: new Date(),
              };

              // Update Firestore document
              const invoiceRef = firebase.firestore().collection('orgs').doc(invoice.orgId).collection('invoices').doc(invoice.id);

              await invoiceRef.update({
                internalFiles: firebase.firestore.FieldValue.arrayUnion(invoiceFile),
              });

              invoice.internalFiles.push(invoiceFile);

              // Process the created invoice file object
              console.log('Invoice File:', invoiceFile);
              // Clear the file list
              setFileList([]);
              // Show success message
              message.success('File uploaded successfully.');
              onSuccess(downloadUrl, file);
            })
            .catch((error) => {
              onError(error);
              console.error('Error retrieving download URL:', error);
              message.error('Failed to retrieve download URL.');
            });
        }
      );
    } catch (error) {
      onError(error);
      console.error('Error uploading file:', error);
      message.error('File upload failed.');
    }
  };

  const handleFileClick = (file: InternalFile) => {
    window.open(file.downloadUrl, '_blank');
  };

  return (
    <Drawer
      width={600}
      title={
        <Col>
          <Row wrap={false} style={{ width: "100%" }} align="middle">
            <Col flex={"auto"}>Invoice: {invoice && invoice.invoiceNumber}</Col>
            <Col flex={"145px"} style={{ textAlign: "right" }}>
              <Space>
                <Button
                  onClick={() =>
                    handleCloseDrawer({ visible: false, invoice: null })
                  }
                >
                  Close
                </Button>
              </Space>
            </Col>
          </Row>
          <Col flex={"auto"}>
            <Tabs activeKey={tabType} onChange={setTabType}>
              <TabPane tab="Notes" key="notes" />
              <TabPane tab="Files" key="files" />
              {
                showTags && <TabPane tab="Tags" key="tags" />
              }
            </Tabs>
          </Col>
        </Col>
      }
      placement={"right"}
      closable={false}
      onClose={onClose}
      open={visible}
      destroyOnClose
      footer={
        <div>
          {tabType === "notes" && (
            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Input
                  value={newMessage}
                  onChange={({ target: { value } }) => setNewMessage(value)}
                  placeholder="Enter your message"
                  prefix={<MessageOutlined style={{ color: "gray" }} />}
                  style={{ borderRadius: "4px", marginRight: "8px" }}
                />
                <Button
                  type="primary"
                  onClick={handleSendMessage}
                  icon={<SendOutlined />}
                >
                  Send
                </Button>
              </div>
              <br />

              <>
                <Upload {...props}>
                  {imageFilesList.length >= 8 ? null : uploadButton}
                </Upload>
                <Modal
                  open={previewOpen}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancel}
                >
                  <img
                    alt="example"
                    style={{ width: "100%" }}
                    src={previewImage}
                  />
                </Modal>
              </>
            </div>
          )}

          {tabType === "files" && (
            <div>
              <Dragger
                fileList={fileList}
                beforeUpload={(file) => {
                  const isPdf = file.type === "application/pdf";
                  if (!isPdf) {
                    message.error("You can only upload PDF files.");
                  }
                  return isPdf;
                }}
                onChange={(info) => {
                  const { fileList } = info;
                  setFileList(fileList);
                }}
                customRequest={handleUploadFile}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag PDF files to this area to upload
                </p>
              </Dragger>
            </div>
          )}
        </div>
      }
    >
      {tabType === "notes" && (
        <div>
          <Spin spinning={loading} indicator={Spinner}>
            <div style={{ maxHeight: "calc(100% - 72px)", overflowY: "auto" }}>
              {messages.map((message, index) => (
                <MessageItem key={index} message={message} />
              ))}
              <div ref={messagesEndRef} />
            </div>
          </Spin>
        </div>
      )}

      {tabType === "files" && (
        <div>
          <Spin spinning={loadingFiles} indicator={Spinner}>
            <div
              style={{ maxHeight: "calc(100% - 72px)", overflowY: "auto" }}
            ></div>
            {invoice && (
              <List
                dataSource={invoice.internalFiles}
                renderItem={(file) => (
                  <List.Item
                    key={file.name}
                    onClick={() => handleFileClick(file)}
                    style={{ cursor: "pointer" }}
                  >
                    <List.Item.Meta
                      avatar={<FilePdfOutlined style={{ color: "red" }} />}
                      title={file.name}
                      // description={`Uploaded by: ${file.uploadedBy}`}
                    />
                  </List.Item>
                )}
              />
            )}
          </Spin>
        </div>
      )}

      {tabType === "tags" && (
        <div>
          <Select
            value={selectedOption}
            style={{ width: "100%" }}
            placeholder="Add tags..."
            // onSelect={handleAddTag}
            onSelect={handleAddTag}
            optionLabelProp="label"
          >
            {tags && tags.filter((t) => !selectedTags.includes(t.id)).map((tag) => (
              <Option value={tag.id} label={tag.name} key={tag.id}>
                <div className="demo-option-label-item">{tag.name}</div>
              </Option>
            ))}
          </Select>

          <div style={{ marginTop: "16px" }}>
            {selectedTags &&
              selectedTags.length > 0 &&
              selectedTags.map((tagId) => {
                const tag = tags.find((tag) => tag.id === tagId);
                if (tag) {
                  return (
                    <Tag
                      color={tag.color}
                      key={tag.id}
                      closable
                      onClose={() => handleRemoveTag(tagId)}
                    >
                      {tag.name}
                    </Tag>
                  );
                } else {
                  return null;
                }
              })}
          </div>
        </div>
      )}
    </Drawer>
  );
};

export default InternalNotes;
