import React, { createContext } from "react";
import { Affix, Col, Divider, PageHeader, Row, Space, Spin } from "antd";
import DateRangePicker from "../sales/components/DateRangePicker";
import { useUsageReport } from "../../../app/services/hooks/useUsageReport";
import UsageRangePicker from "./components/UsageRangePicker";
import UsageInventoryChart from "./components/UsageInventoryChart";
import SelectViewInterval from "./components/SelectViewInterval";
import UsageBarChart from "./components/UsageBarChart";
import UsageInvoices from "./components/UsageInvoices";
import OverbookedChart from "./components/OverbookedChart";
import UsageTable from "./components/UsageTable";

export const UsageReportContext = createContext();
const UsageReport = () => {
  const { state, actions } = useUsageReport();

  return (
    <div>
      <UsageReportContext.Provider value={{ state, actions }}>
        <Spin spinning={state.loading}>
          <Affix>
            <PageHeader className="PageHeader" title="Usage Report" />
          </Affix>
          <div style={{ margin: "16px", paddingTop: "56px" }}>
            <Row justify={"space-between"} wrap={false} align={"top"}>
              <Col span={12}>
                <UsageRangePicker />
              </Col>
            </Row>
            <Divider />
            <OverbookedChart />
            <br />
            <UsageTable />
            <br />
          </div>
        </Spin>
      </UsageReportContext.Provider>
    </div>
  );
};

export default UsageReport;
