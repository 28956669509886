
import notificationError from "../../../system-components/toasters/notificationError";

import {
  INV_BUNDLE_ITEM,
  INV_BUNDLE_ITEM_ID,
  INVOICE,
  INVOICE_ESTIMATE,
  ROW_TYPE_SUBTOTAL,
} from "../../../utils/models/modelConstants/modelConstants";
import { invoices } from "../../firestore/queries/invoiceQueries";
import { DetermineApplyTurnaround } from "../helpers/determineApplyTurnaround";
import { DetermineApplyBundleTurnaround } from "../helpers/determineBundleApplyTurnaround";
import { InventoryItem } from "../../../interfaces/inventoryItem";
import { Invoice } from "../../../interfaces/invoice";

import { useState, useEffect } from "react";
import { useFirestore } from "react-redux-firebase";
import { authSelector } from "../../../../domains/auth/authSlice";
import { useSelector } from "react-redux";


// Define TypeScript interfaces for function parameters and objects used in the function
export type StartEndOptions = {
  rentalDateStart: Date;
  rentalDateEnd: Date;
  useTurnaround: boolean;
}

interface ErrorState {
  hasError: boolean;
  error: Error | null;
}

interface RefreshState {
  refresh: boolean;
  count: number;
}

/**
 * Fetches and processes a range of invoices from an inventory dataset.
 * 
 * @param {StartEndOptions} startEndOpts - Object containing start and end options for filtering invoices.
 * @param {any[]} inventory - Array of inventory items.
 * @param {string[]} ignoreInvoicesForAvailability - Array of invoice IDs to exclude from processing.
 * @returns An object containing:
 * - `data: InventoryItem[]`: Processed array of inventory items.
 * - `fetching: boolean`: Indicates if the hook is currently fetching data.
 * - `error: ErrorState`: Object representing the current error state.
 * - `refresh: () => void`: Function to re-fetch or refresh the data.
 */

export function useInvoicesInRange(
  startEndOpts: StartEndOptions,
  inventory: InventoryItem[],
  ignoreInvoicesForAvailability: string[]
): {
  // Returns the following.
  data: InventoryItem[];
  fetching: boolean;
  error: ErrorState;
  refresh: () => void;
} {

  // console.log("useInvoicesInRange in action");
  // console.log("startEndOpts", startEndOpts);
  // console.log("inventory", inventory);
  // console.log("ignoreInvoicesForAvailability", ignoreInvoicesForAvailability);

  const [data, setData] = useState<any[]>([]);
  const [invoicesInRange, setInvoicesInRange] = useState<Invoice[]>([]);
  const [fetching, setFetching] = useState(false);

  const [error, setError] = useState<ErrorState>({
    hasError: false,
    error: null,
  });
  const [refresh, setRefresh] = useState<RefreshState>({
    refresh: false,
    count: 0,
  });

  const { authenticated, fsOrgPrefix, orgData } = useSelector(authSelector);
  const firestore = useFirestore();

  // Function to refresh the data
  const refreshData = (): void => {
    setRefresh({
      refresh: true,
      count: 0,
    });
  };

  /**
   * Fetches invoices within a specified date range and manages the invoice data state.
   *
   * The hook triggers whenever there are changes in authentication status, refresh state,
   * start and end dates of the rental period, or inventory data. It ensures data is fetched
   * and processed only under valid conditions, such as user authentication and valid date range.
   *
   * The process involves several key steps:
   * 1. Checking pre-conditions like authentication status, refresh state, and date validity.
   * 2. Initiating the data fetch process and resetting relevant states.
   * 3. Handling the fetched data on successful retrieval.
   * 4. Managing errors that might occur during the fetch process.
   *
   * useEffect Dependencies:
   * - authenticated: Ensures that data is fetched only when the user is authenticated.
   * - refresh: Monitors the refresh state to avoid unnecessary re-fetching.
   * - startEndOpts.rentalDateStart and startEndOpts.rentalDateEnd: The date range for fetching invoices.
   * - inventory: Triggers re-fetching if the inventory data changes.
   */
  useEffect(() => {
    // Skip fetching if the user is not authenticated, the refresh count is nonzero,
    // the date range is invalid, or if a fetch operation is already in progress.
    if (
      !authenticated ||
      refresh.count > 0 ||
      !startEndOpts.rentalDateStart ||
      !startEndOpts.rentalDateEnd ||
      fetching
    ) {
      return;
    }

    // Start fetching process: indicate fetching state and reset data states
    setFetching(true);
    setData([]);
    setInvoicesInRange([]);

    try {
      invoices(orgData?.orgTimezone)
        .fetchInvoicesInRange({ firestore }, fsOrgPrefix, startEndOpts)
        .then((res) => {
          setInvoicesInRange(res ?? []);
          setFetching(false);
          setRefresh({ refresh: false, count: refresh.count + 1 });
        });
    } catch (err) {
      console.log(err);
      notificationError(
        "Something went wrong",
        "Please try again or refresh the page"
      );
      setFetching(false);
      setError({
        hasError: true,
        error: err as Error,
      });

      setRefresh({ refresh: false, count: refresh.count + 1 });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    authenticated,
    refresh,
    startEndOpts.rentalDateStart,
    startEndOpts.rentalDateEnd,
    inventory,
  ]);

  /**
   * Processes inventory data based on the invoices in the given date range.
   *
   * This hook triggers whenever there's a change in the invoicesInRange state.
   * It handles the complex task of adjusting inventory availability based on
   * the details of each invoice within the specified range.
   *
   * Steps involved in the process:
   * 1. Initialization of local states and variables.
   * 2. Iteration over each invoice within the specified range.
   * 3. Conditional checks and calculations for each inventory item in the invoices.
   * 4. Accumulation of inventory adjustments in reduceStock array.
   * 5. Final computation of the updated inventory state, considering invoice details.
   *
   * The core logic:
   * - Maps through each inventory item and resets its available and unavailable stocks.
   * - For each invoice, it performs checks to determine if it should be considered for stock calculation.
   * - Applies specific logic for bundled items and standard items to calculate their impact on inventory.
   * - Finally, it updates the inventory state with the new calculated values for each item.
   */

  useEffect(() => {
    let useTurnaround = startEndOpts.useTurnaround;

    let inventoryAdjustments: any[] = [];

    let updatedInventory = inventory.map((x) => {
      const { stock, ...rest } = x;
      return {
        ...rest,
        stock: stock,
        availableStock: stock,
        unavailableStock: 0,
        unavailableEstStock: 0,
      };
    });

    invoicesInRange.forEach((i) => {
      
      if (ignoreInvoicesForAvailability?.includes(i?.id)) return;

      // sum up inventory items to be filtered
      const { selectedItems, type } = i;

      selectedItems.items.forEach((item) => {

        const stillApplicable = DetermineApplyTurnaround({
          useTurnaround: useTurnaround,
          invoice: i,
          item: item,
          queryRentalStartDate: startEndOpts.rentalDateStart,
        });

        const isInvoice = type === INVOICE;
        const isEstimate = type === INVOICE_ESTIMATE;

        if (item.rowType === ROW_TYPE_SUBTOTAL) return; // esc subtotals

        if (item.type === INV_BUNDLE_ITEM) {
          item.bundleItems?.forEach((bi) => {
            const bundleApplicable = DetermineApplyBundleTurnaround({
              useTurnaround: useTurnaround,
              invoice: i,
              bundleItem: bi,
              queryRentalStartDate: startEndOpts.rentalDateStart,
              inheritParentTurnaround: item.inheritParentTurnaround,
              parent: item,
            });

            const exists = inventoryAdjustments.find(
              (e) => e.id === bi[INV_BUNDLE_ITEM_ID]
            );

            const qty = bi.bundleItemQty * item.selectedQty;

            if (exists && bundleApplicable) {
              const invoiceArr = exists.invoices
                ? exists.invoices.filter(
                    (inv: { id: string }) => inv.id !== i.id
                  )
                : [];
              const obj = {
                id: exists.id,
                invoiceQty: isInvoice
                  ? exists.invoiceQty + qty
                  : exists.invoiceQty,
                estimateQty: isEstimate
                  ? exists.estimateQty + qty
                  : exists.estimateQty,
                invoices: isInvoice ? [i, ...invoiceArr] : exists.invoices,
              };

              inventoryAdjustments = inventoryAdjustments.filter((i) => i.id !== bi.bundleItemId);

              inventoryAdjustments.push(obj);
            } else {
              if (!bundleApplicable) return;

              inventoryAdjustments.push({
                id: bi.bundleItemId,
                invoiceQty: isInvoice ? qty : 0,
                estimateQty: isEstimate ? qty : 0,
                invoices: isInvoice ? [i] : [],
              });
            }
          });
        }

        const exists = inventoryAdjustments.find((i) => i.id === item.id);

        if (exists && stillApplicable) {

          const invoiceArr = exists.invoices ? exists.invoices.filter((inv: { id: string }) => inv.id !== i.id) : [];
          const obj = {
            id: exists.id,
            invoiceQty: isInvoice ? (exists.invoiceQty + item.selectedQty) : exists.invoiceQty,
            estimateQty: isEstimate ? (exists.estimateQty + item.selectedQty) : exists.estimateQty,
            invoices: isInvoice ? [i, ...invoiceArr] : exists.invoices,
          };

          inventoryAdjustments = inventoryAdjustments.filter((i) => i.id !== item.id);
          inventoryAdjustments.push(obj);
        } else {
          if (!stillApplicable) return;

          inventoryAdjustments.push({
            id: item.id,
            invoiceQty: isInvoice ? item.selectedQty : 0,
            estimateQty: isEstimate ? item.selectedQty : 0,
            invoices: isInvoice ? [i] : [],
          });
        }
      });
    });

    inventoryAdjustments.forEach((s) => {

      const item = inventory.find((ui) => ui.id === s.id);

      if (item) {
        
        const { stock, ...rest} = item;

        // console.log("ITEM", item.name);
        // console.log("ID", item.id);
        // console.log("ITEM DATA", item);

        let newInvoiceStock = stock ? stock - s.invoiceQty : null;

        if (item.type === INV_BUNDLE_ITEM) {
           // Calculate based on the bundle's own stock and assignments first
  // newInvoiceStock = stock - s.invoiceQty;

  // Then, check if any subcomponent limits the availability further
  const bundleItemStocks = item.bundleItems?.map((bi) => {
    const bis = inventoryAdjustments.find((s) => s.id === bi.bundleItemId);
    const subComponent = inventory.find((invItem) => invItem.id === bi.bundleItemId);

    if (!subComponent || !bis) return Infinity; // Don't limit if subcomponent not found

    const biStock = subComponent.stock || 0;
    const biAvailable = biStock - bis.invoiceQty;
    return Math.floor(biAvailable / bi.bundleItemQty);
  });

  if (bundleItemStocks && bundleItemStocks.length > 0) {
    const subComponentLimit = Math.min(...bundleItemStocks);
    newInvoiceStock = Math.min(newInvoiceStock ?? 0, subComponentLimit);
  }
        }
  
        const updated = {
          ...rest,
          stock: stock,
          availableStock: newInvoiceStock,
          unavailableStock: s.invoiceQty ? s.invoiceQty : 0,
          unavailableEstStock: s.estimateQty ? s.estimateQty : 0,
          invoices: s.invoices ? s.invoices : [],
        };

        updatedInventory = [...updatedInventory].filter((i) => i.id !== s.id);
        updatedInventory.push(updated);
      }
    });

    setData([...updatedInventory]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoicesInRange]);

  return { 
    data: data, 
    fetching: fetching, 
    error: error, 
    refresh: refreshData 
  };
}
