import { useEffect, useState } from "react";
import moment from "moment";
import {
  useGetCategoriesQuery,
  useGetInvoicesForRangeQuery,
} from "../api";
import { useFirestore } from "react-redux-firebase";
import { GetSubtotalsForInvoices } from "./helpers/reports/getSubtotalsForInvoices";
import { GetSubtotalsByInventory } from "./helpers/reports/getSubtotalsByInventory";
import { sortByNumberValue } from "../../utils/sorters/sortBy";
import { useSelector } from "react-redux";
import { authSelector } from "../../../domains/auth/authSlice";
import { CheckSelectedItem } from "../../utils/models/checkers/checkSelectedItem";

export function useSalesReport() {

  const { fsOrgPrefix, orgData } = useSelector(authSelector);
  const firestore = useFirestore();

  const [dateRange, setDateRange] = useState({
    start: moment().subtract(7, "days").startOf("day"),
    end: moment().endOf("day"),
  });

  const [loading, setLoading] = useState(false);
  const [itemFilters, setItemFilters] = useState([]);
  const [totalSales, setTotalSales] = useState(0);
  const [chartData, setChartData] = useState([]);
  const [inventory, setInventory] = useState([]);
  const [selectedInvItem, setSelectedInvItem] = useState(null);
  const [selectedInvAmt, setSelectedInvAmt] = useState(0);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  
  const {
    data: invoices,
    isFetching: fetchingInvoices,
    refetch: refetchInvoices,
  } = useGetInvoicesForRangeQuery({
    db: { firestore },
    orgPrefix: fsOrgPrefix,
    dateStart: dateRange.start ?? null,
    dateEnd: dateRange.end ?? null,
    orgTimezone: orgData?.orgTimezone,
    invoiceOnly: true,
  });

  const { data: categories, isFetching: fetchingCategories } =
    useGetCategoriesQuery({
      db: { firestore },
      orgPrefix: fsOrgPrefix,
    });


  useEffect(() => {
    refetchInvoices();
  }, [dateRange]);

  useEffect(() => {

    let inventoryInRange = invoices?.flatMap((invoice) => {
      const selectedItems = invoice?.selectedItems?.items ?? [];
      return selectedItems.map((item) => {
        const selectedItemChecked = CheckSelectedItem(item);
    
        if (selectedItemChecked?.rowType !== "singleItem") {
          selectedItemChecked.id = selectedItemChecked?.rowType;
        }
    
        return selectedItemChecked;
      });
    });
    
    const uniqueInventory = inventoryInRange?.reduce((acc, currentItem) => {
      const { id, selectedQty = 0, rowTotal = 0, rowType, uuid } = currentItem;
      const key = rowType === "singleItem" ? id : uuid;
    
      if (acc[key]) {
        acc[key].selectedQty += selectedQty;
        acc[key].rowTotal += rowTotal;
      } else {
        acc[key] = { ...currentItem }; 
      }
    
      return acc;
    }, {});

    setInventory(uniqueInventory);

  }, [invoices]);

  useEffect(() => {
    if (fetchingInvoices || fetchingCategories)
      return setLoading(true);

    setLoading(false);
    setTotalSales(GetSubtotalsForInvoices(invoices));

    const invList = GetSubtotalsByInventory({
      invoices,
      uniqueInventory: inventory,
      filterItemIds: itemFilters,
    });

    setChartData(invList);

    const filteredInvIds = invList?.map((i) => i?.inventoryId);
    const filteredInvoices = filterInvoices({
      inventoryIds: filteredInvIds,
      invoices,
    });

    const filteredSelected = filteredInvoices?.filter((inv) => {
      return !!inv?.selectedItemsQueryHook.includes(selectedInvItem);
    });

    setFilteredInvoices(
      selectedInvItem
        ? sortByNumberValue(filteredSelected, "invoiceNumber")
        : sortByNumberValue(filteredInvoices, "invoiceNumber")
    );
    
  }, [invoices, categories, itemFilters, inventory, selectedInvItem]);

  useEffect(() => {
    let amt = 0;
    if (itemFilters?.length > 0) {
      chartData?.forEach((i) => {
          amt = amt + i?.total;
      });
    }

    if (selectedInvItem) {
      const item = chartData?.find((i) => i?.inventoryId === selectedInvItem);
      amt = item?.total;
    }

    setSelectedInvAmt(amt);
  }, [selectedInvItem, chartData, itemFilters]);

  const changeDateRange = (dates) => {
    setDateRange({
      start: dates?.[0]?.startOf("day"),
      end: dates?.[1]?.endOf("day"),
    });
  };

  const changeItemFilters = (filters) => {
    setSelectedInvItem(null);
    setItemFilters([...filters]);
  };

  const changeSelectedInvItem = (v) => {
    setSelectedInvItem(v);
  };

  return {
    state: {
      dateRange,
      totalSales,
      categories,
      fetchingCategories,
      fetchingInvoices,
      inventory,
      chartData,
      selectedInvItem,
      invoices,
      filteredInvoices,
      selectedInvAmt,
      itemFilters,
      loading,
    },
    actions: {
      changeDateRange,
      changeItemFilters,
      changeSelectedInvItem,
      setDateRange,
    },
  };
}

const filterInvoices = ({invoices, inventoryIds}) => {
  if (!invoices || !inventoryIds) return [];

  return invoices.filter((invoice) =>
    invoice.selectedItemsQueryHook?.some((id) => inventoryIds.includes(id))
  );
};


