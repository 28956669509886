import "./App.less";
import { Layout, Menu, Spin } from "antd";
import { withRouter, Switch, Route, useHistory, Redirect } from "react-router-dom";
import Sidebar from "./domains/sidebar/Sidebar";

import {
  ROUTE_ADMIN_CREATE_INVENTORY,
  ROUTE_ADMIN_CUSTOMERS,
  ROUTE_ADMIN_CUSTOMERS_PAYMENT,
  ROUTE_ADMIN_INTEGRATIONS,
  ROUTE_ADMIN_INVENTORY,
  ROUTE_ADMIN_SETTINGS_EMAIL,
  ROUTE_ADMIN_SETTINGS_WORDS,
  ROUTE_ADMIN_SETTINGS_GENERAL,
  ROUTE_ADMIN_TRUCKS,
  ROUTE_ADMIN_USERS,
  ROUTE_CREATE_INVOICE,
  ROUTE_CREATE_INVOICE_FROM_CART,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_INVENTORY,
  ROUTE_INVOICES,
  ROUTE_LOGIN,
  ROUTE_QUICK_REPORT,
  ROUTE_RECEIVE_INVOICE_PAYMENT,
  ROUTE_REPORTS,
  ROUTE_REPORTS_DEPRECIATION,
  ROUTE_REPORTS_SALES,
  ROUTE_REPORTS_USAGE,
  ROUTE_ROUTES,
  ROUTE_VIEW_EDIT_INVOICE,
  ROUTE_ADMIN_VENUES,
  ROUTE_REPORTS_TAX,
  ROUTE_ADMIN_EMBEDDED_CHECKOUT,
  ROUTE_ADMIN_CREATE_PART,
  ROUTE_ADMIN_CREATE_SERVICE,
  ROUTE_ADMIN_VENDORS,
  ROUTE_DASHBOARD,
  ROUTE_CHANGE_LOG,
  ROUTE_MOBILE_APPS,
  ROUTE_ADMIN_SETTINGS_PERMISSIONS,
  ROUTE_ADMIN_FILE_MANAGER,
  ROUTE_ADMIN_BILLING,
  ROUTE_VIEW_PREVIEW_INVOICE,
} from "./app/routes";
import Invoices from "./domains/invoices/Invoices";
import QuickReport from "./domains/quick-report/QuickReport";
import Reports from "./domains/reports/Reports";
import AdminUsers from "./domains/admin-users/AdminUsers";
import AdminIntegrations from "./domains/admin-integrations/AdminIntegrations";
import AdminSettingsEmail from "./domains/admin-settings/AdminSettings.Email";
import AdminSettingsGeneral from "./domains/admin-settings/AdminSettings.General";

import { useEffect, useState } from "react";
import Login from "./domains/login/Login";
import { authSelector, verifyAuth } from "./domains/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { Spinner } from "./app/ui/spinner";
import AddInventory from "./domains/admin-inventory/components/AddInventory";
import AdminCustomers from "./domains/admin-customers/AdminCustomers";
import AdminVenues from "./domains/admin-venues/AdminVenues";
import AddInvoice from "./domains/invoices/InvoiceDetail";

import EditInvoice from "./domains/invoices/EditInvoice";
import InventoryCartWrapper from "./domains/inventory/components/InventoryCartWrapper";
import CartInvoice from "./domains/invoices/CartInvoice";
import AdminCustomersReceivePayment from "./domains/admin-customers/AdminCustomersReceivePayment";
import InvoicesReceivePayment from "./domains/invoices/InvoicesReceivePayment";
import NewRoute from "./domains/routes/NewRoute";
import AdminTrucks from "./domains/admin-trucks/AdminTrucks";
import SalesReport from "./domains/reports/sales/SalesReport";
import UsageReport from "./domains/reports/usage/UsageReport";
import DepreciationReport from "./domains/reports/depreciation/DepreciationReport";
import ForgotPasswordPage from "./domains/auth/ForgotPasswordPage";
import { init as i18nInit } from "./i18n";
import TaxReport from "./domains/reports/tax/TaxReport";
import { LicenseInfo } from "@mui/x-license-pro";
import AdminEmbeddedCheckout from "./domains/admin-embedded-checkout/AdminEmbeddedCheckout";
import InventoryContainer from "./domains/admin-inventory/InventoryContainer";
import AddPart from "./domains/admin-inventory/components/parts/AddPart";
import AddServiceItem from "./domains/admin-inventory/components/serviceItems/AddServiceItem";
import AdminVendors from "./domains/admin-vendors/AdminVendors";
import ChangeLogView from "./domains/change-log/change-log";
import MobileAppsView from "./domains/mobile-apps/mobile-apps";
import AdminSettingsWords from "./domains/admin-settings/AdminSettings.Words";
import { hasPermission } from "./domains/admin-permissions/usePermissions";
import PermissionsView from "./domains/admin-permissions/permissions-view";
import { FileManagerView } from "./domains/file-manager/view";
import DashboardView from "./domains/dashboard/dashboard-view";
import { checkSubscriptionLevel } from "../src/app/interfaces/features-set-permissions";

import BillingPage from "./domains/admin-billing/billing-page";
import LockedView from "./domains/admin-billing/locked-view";
import ProfileDropdown from "./domains/sidebar/components/ProfileDropdown";
import { Box, Button, Card, Container, Stack, Typography, alpha } from "@mui/material";
import { COLOR_DARK_BG_1 } from "./app/ui/colorConstants";
import MuiInvoice from "./domains/mui-invoice/MuiInvoice";
import PreviewInvoice from "./domains/invoices/PreviewInvoice";

LicenseInfo.setLicenseKey("6db4e7eb23b24bf023fa851d10189adaTz04NjEzNCxFPTE3NDE2NTcyNzUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

const { Content } = Layout;
function App({ history }) {
  const { authenticated, verifyingAuthInProgress, userData, permissionsData, orgData } = useSelector(authSelector);

  const [sidebarFormat, setSidebarFormat] = useState(false);
  const firebase = useFirebase();
  const firestore = useFirestore();
  const dispatch = useDispatch();
  const windowHistory = useHistory();
  const [trialEnded, setTrialEnded] = useState(false);

  useEffect(() => {
    if (orgData && orgData.createdOn) {
      if (orgData.subscriptionStatus !== "trial") {
        return;
      }

      const orgCreatedOn = orgData.createdOn.toDate();
      const currentDate = new Date();
      const diffTime = Math.abs(currentDate - orgCreatedOn);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays > 14) {
        setTrialEnded(true);
      }
    }
  }, [orgData]);

  useEffect(() => {
    const initializeI18n = async () => {
      await i18nInit();
    };

    dispatch(verifyAuth({ firestore, firebase }, history));

    if (history.location.pathname === "/" || history.location.pathname === ROUTE_FORGOT_PASSWORD) {
      history.push(ROUTE_DASHBOARD);
    }

    initializeI18n();
  }, []);

  useEffect(() => {
    const path = windowHistory.location.pathname;
    if (
      path !== ROUTE_LOGIN &&
      // path !== ROUTE_ROUTES &&
      path !== ROUTE_FORGOT_PASSWORD
    ) {
      setSidebarFormat(true);
    } else {
      setSidebarFormat(false);
    }
  }, [windowHistory.location.pathname, authenticated]);


  const ProtectedRoute = ({ component: Component, render: RenderComponent, ...rest }) => {
    const { path, subscriptionFeaturePath } = rest;
    return (
      <Route
        {...rest}
        render={(props) => {
          console.log("path", path);  
          console.log("subscriptionFeaturePath", subscriptionFeaturePath);
          console.log("permissionsData", permissionsData);
          if (hasPermission(path, userData, permissionsData)) {
            if (subscriptionFeaturePath && checkSubscriptionLevel(subscriptionFeaturePath, orgData)) {
              return RenderComponent ? RenderComponent(props) : <Component {...props} />;
            } else if (subscriptionFeaturePath && !checkSubscriptionLevel(subscriptionFeaturePath, orgData)) {
              return <LockedView orgData={orgData} userData={userData} subscriptionFeaturePath={subscriptionFeaturePath} />;
            } else {
              return RenderComponent ? RenderComponent(props) : <Component {...props} />;
            }
          } else {
            return <Redirect to="/unauthorized" />;
          }
        }}
      />
    );
  };

  
  // const ProtectedRoute = ({ component: Component, ...rest }) => {
  //   const { path, subscriptionFeaturePath } = rest;
  //   return (
  //     <Route
  //       {...rest}
  //       render={(props) => {
  //         if (hasPermission(path, userData, permissionsData)) {
  //           if (subscriptionFeaturePath && checkSubscriptionLevel(subscriptionFeaturePath, orgData)) {
  //             return <Component {...props} />;
  //           } else if (subscriptionFeaturePath && !checkSubscriptionLevel(subscriptionFeaturePath, orgData)) {
  //             return <LockedView orgData={orgData} userData={userData} subscriptionFeaturePath={subscriptionFeaturePath} />;
  //           } else {
  //             return <Component {...props} />;
  //           }
  //         } else {
  //           return <Redirect to="/unauthorized" />;
  //         }
  //       }}
  //     />
  //   );
  // };

  if (trialEnded) {
    return (
      <Container maxWidth={false} disableGutters>
        <Box
          sx={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            justifyContent: "center", // Centers horizontally
            alignItems: "center", // Centers vertically
            flexDirection: "column",
            bgcolor: (theme) => alpha(theme.palette.grey[500], 0.04),
          }}
        >
          <Card sx={{ mt: 4, height: "300px" }}>
            {authenticated && userData ? (
              <Stack direction={"row"} alignItems={"center"}>
                <div
                  style={{
                    backgroundColor: COLOR_DARK_BG_1,
                    height: "300px",
                  }}
                >
                  <Menu
                    style={{
                      backgroundColor: COLOR_DARK_BG_1,
                      height: "100%",
                    }}
                    theme={"dark"}
                  >
                    <Menu.Item
                      key={"company_header"}
                      className="CompanyHeader"
                      style={{
                        height: "100%",
                      }}
                    >
                      <ProfileDropdown history={history} />
                    </Menu.Item>
                  </Menu>
                </div>

                <Stack sx={{ p: 2 }}>
                  <Typography variant="h6">Your trial has ended. Please contact support to continue using Adelie.</Typography>

                  <Typography variant="body2">info@adelielogistics.com</Typography>

                  <Button
                    size="small"
                    sx={{ mt: 2, width: "fit-content" }}
                    variant="contained"
                    onClick={() => {
                      // email to info@adelielogistics
                      const email = "info@adelielogistics.com";
                      const subject = encodeURIComponent("Trial Ended - Subscription Plan Change Request");
                      const body = encodeURIComponent("Please change my subscription plan to the following: \n");

                      window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
                    }}
                  >
                    Contact To Renew
                  </Button>
                </Stack>
              </Stack>
            ) : (
              <Spin indicator={Spinner} style={{ marginTop: "120px" }} />
            )}
          </Card>
        </Box>
      </Container>
    );
  }

  return (
    <div>
      <Layout className="App">
        {sidebarFormat && authenticated && permissionsData && userData ? <Sidebar userData={userData} permissionsData={permissionsData} /> : null}

        {!verifyingAuthInProgress ? (
          <Content
            style={{
              marginLeft: sidebarFormat ? "" : 0,
              overflowY: "auto",
            }}
          >
            <Switch>
              <Route exact path={ROUTE_LOGIN} component={Login} />
              <Route exact path={ROUTE_FORGOT_PASSWORD} component={ForgotPasswordPage} />

              {permissionsData && userData && (
                <>
                  <ProtectedRoute exact path={ROUTE_DASHBOARD} component={DashboardView} />

                  <ProtectedRoute exact path={ROUTE_INVENTORY} component={InventoryCartWrapper} />

                  <ProtectedRoute exact path={ROUTE_INVOICES} component={Invoices} />

                  {orgData && orgData.useLegacyInvoiceView ? (
                    <ProtectedRoute exact path={ROUTE_CREATE_INVOICE} component={AddInvoice} />
                  ) : (
                    <ProtectedRoute exact path={ROUTE_CREATE_INVOICE} render={(props) => <MuiInvoice {...props} orgData={orgData} />} />
                  )}

                  <ProtectedRoute path={ROUTE_VIEW_EDIT_INVOICE} component={EditInvoice} />

                  <ProtectedRoute path={ROUTE_VIEW_PREVIEW_INVOICE} component={PreviewInvoice} />

                  <ProtectedRoute exact path={ROUTE_CREATE_INVOICE_FROM_CART} component={CartInvoice} />

                  <ProtectedRoute exact path={ROUTE_RECEIVE_INVOICE_PAYMENT} component={InvoicesReceivePayment} />

                  <ProtectedRoute subscriptionFeaturePath={"routing"} exact path={ROUTE_ROUTES} component={NewRoute} />

                  <ProtectedRoute exact path={ROUTE_QUICK_REPORT} subscriptionFeaturePath={"prepareTheItems"} component={QuickReport} />

                  <ProtectedRoute exact path={ROUTE_REPORTS} component={Reports} />

                  <ProtectedRoute exact path={ROUTE_REPORTS_SALES} component={SalesReport} />

                  <ProtectedRoute exact path={ROUTE_REPORTS_TAX} component={TaxReport} />

                  <ProtectedRoute exact subscriptionFeaturePath={"reports.usage"} path={ROUTE_REPORTS_USAGE} component={UsageReport} />

                  <ProtectedRoute exact subscriptionFeaturePath={"reports.depreciation"} path={ROUTE_REPORTS_DEPRECIATION} component={DepreciationReport} />

                  <ProtectedRoute exact path={ROUTE_ADMIN_USERS} component={AdminUsers} />

                  <ProtectedRoute exact path={ROUTE_ADMIN_INVENTORY} component={InventoryContainer} />

                  <ProtectedRoute exact path={ROUTE_ADMIN_CREATE_INVENTORY} component={AddInventory} />

                  <ProtectedRoute exact path={ROUTE_ADMIN_CREATE_PART} component={AddPart} />

                  <ProtectedRoute exact path={ROUTE_ADMIN_CREATE_SERVICE} component={AddServiceItem} />

                  <ProtectedRoute exact path={ROUTE_ADMIN_CUSTOMERS} component={AdminCustomers} />

                  <ProtectedRoute exact subscriptionFeaturePath={"vendors"} path={ROUTE_ADMIN_VENDORS} component={AdminVendors} />

                  <ProtectedRoute exact subscriptionFeaturePath={"venues"} path={ROUTE_ADMIN_VENUES} component={AdminVenues} />

                  <ProtectedRoute exact path={ROUTE_ADMIN_CUSTOMERS_PAYMENT} component={AdminCustomersReceivePayment} />

                  <ProtectedRoute exact subscriptionFeaturePath={"trucks"} path={ROUTE_ADMIN_TRUCKS} component={AdminTrucks} />

                  <ProtectedRoute exact subscriptionFeaturePath={"fileManager"} path={ROUTE_ADMIN_FILE_MANAGER} component={FileManagerView} />

                  <ProtectedRoute path={ROUTE_ADMIN_INTEGRATIONS} component={AdminIntegrations} />

                  <ProtectedRoute path={ROUTE_ADMIN_BILLING} component={BillingPage} />

                  <ProtectedRoute exact path={ROUTE_ADMIN_SETTINGS_GENERAL} component={AdminSettingsGeneral} />

                  <ProtectedRoute exact path={ROUTE_ADMIN_SETTINGS_EMAIL} component={AdminSettingsEmail} />

                  <ProtectedRoute exact subscriptionFeaturePath={"invoiceCustomization"} path={ROUTE_ADMIN_SETTINGS_WORDS} component={AdminSettingsWords} />

                  <ProtectedRoute exact path={ROUTE_ADMIN_SETTINGS_PERMISSIONS} component={PermissionsView} />

                  <ProtectedRoute exact path={ROUTE_ADMIN_EMBEDDED_CHECKOUT} component={AdminEmbeddedCheckout} />

                  <ProtectedRoute exact path={ROUTE_CHANGE_LOG} component={ChangeLogView} />

                  <ProtectedRoute exact path={ROUTE_MOBILE_APPS} component={MobileAppsView} />
                </>
              )}
            </Switch>
          </Content>
        ) : (
          <Spin indicator={Spinner} style={{ marginTop: "120px" }} />
        )}
      </Layout>
    </div>
  );
}

export default withRouter(App);
