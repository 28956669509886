import React from "react";
import { Box, CircularProgress, Grid, Container, Tab, Tabs } from "@mui/material";
import { useSalesReport } from "../../../app/services/hooks/useSalesReport";

import SalesStatistic from "./components/SalesStatistic";
import SalesChart from "./components/SalesChart";
import SalesInvoices from "./components/SalesInvoices";
import SalesDateRangePicker from "./components/DateRangePicker";
import RevenueReport from "./RevenueReport";
import { Payment } from "../../../app/interfaces/payment";


const SalesReport: React.FC = () => {

  const { state, actions } = useSalesReport();

  const [selectedTab, setSelectedTab] = React.useState('sales'); // 'sales' | 'revenue'

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setSelectedTab(newValue);
  };

  return (
    <Container>
      {state.loading ? (
        <Box
          sx={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box style={{ margin: 16 }}>
          <Tabs value={selectedTab} onChange={handleTabChange}>
            <Tab label="Invoiced" value="sales" />
            <Tab label="Payments" value="revenue" />
          </Tabs>

          {selectedTab === "sales" && (
            <>
              <br />
              <Grid
                container
                spacing={2}
                justifyContent="space-between"
                alignItems="top"
              >
                <Grid item xs={12} md={6}>
                  <SalesDateRangePicker state={state} actions={actions} />
                </Grid>

                <Grid item xs={12} md={6}>
                  <SalesStatistic state={state} actions={actions} />
                </Grid>

              </Grid>
              <br />

              <SalesChart state={state} actions={actions} />
              <br />
              <SalesInvoices state={state} actions={actions} />
            </>
          )}

          {selectedTab === "revenue" && (
            <>
            <br />
           <RevenueReport />
           </>
          )}
        </Box>
      )}
    </Container>
  );
};

export default SalesReport;
