import {
  Badge,
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";

import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { useRef, useState } from "react";
import { Breakpoints, useCurrentBreakpoint, useResponsiveFontSize } from "../../../app/hooks/use-responsive-font";
import Iconify from "../../../app/iconify";

// ----------------------------------------------------------------------

type Props = {
  item: any;
  index: number;
  handleUpdateSelectedItem: (index: number, item: any) => void;
  onDeleteRow: (index: number) => void;
};


export default function DescriptionOnlyRow({
  item,
  index,
  handleUpdateSelectedItem,
  onDeleteRow,
}: Props) {

  // Text Field Refs
  const descriptionRef = useRef<HTMLInputElement>(null);

  const responseiveFontSize = useResponsiveFontSize();
  const theme = useTheme();

  const { currentBreakpoint, isSMAndDown, isMDAndUp } =
    useCurrentBreakpoint();

  const [itemDescription, setItemDescription] = useState(item?.selectedDescription || "");

  const updateItem = () => {
    item.selectedDescription = itemDescription;
    handleUpdateSelectedItem(item.listIndex, item);
  };

  return (
    <Box
      sx={{
        py: 1,
        pl: 1,
        pr: currentBreakpoint === Breakpoints.LG ? 1 : 0,
        alignContent: "center",
        borderRadius: 1,
        backgroundColor: alpha(theme.palette.info.lighter, 0.6),
      }}
    >
      <Grid container spacing={{ xs: 2, lg: 1, xl: 1 }} alignItems="center">
        <Grid item xs={6} lg={3}>
          <Stack direction={"row"} gap={1} alignItems={"center"}>
          <Badge badgeContent={index + 1} color="primary" sx={{mr: 2}}>
              <DragIndicatorIcon />
            </Badge>
            <Typography fontSize={responseiveFontSize}>Label</Typography>
          </Stack>
        </Grid>

        <Grid item xs={4} md={3} lg={3.5}>
          <TextField
            inputRef={descriptionRef}
            multiline={true}
            maxRows={5}
            fullWidth
            inputProps={{
              style: {
                fontSize: responseiveFontSize,
              },
            }}
            InputLabelProps={{ style: { fontSize: responseiveFontSize } }}
            size="small"
            label="Description"
            variant="outlined"
            value={itemDescription}
            onChange={(e) => setItemDescription(e.target.value)}
            onBlur={updateItem}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (descriptionRef.current) {
                  descriptionRef.current.blur();
                }
              }
            }}
          />
        </Grid>

        {isMDAndUp && <Grid item md={1.5} lg={5} />}

        <Grid item xs={2} md={0.5}>
        <Box
            display="flex"
            justifyContent={isSMAndDown ? "flex-end" : "flex-start"}
          >
            <Button
              size="small"
              color="error"
              startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
              onClick={() => onDeleteRow(item.index)}
            />
          </Box>
        </Grid>
        
      </Grid>
    </Box>
  );
}
