import {
  Badge,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { FormatToLocalCurrency } from "../../../app/utils/currency/formatToLocalCurrency";
import Iconify from "../../../app/iconify";
import { useEffect, useRef, useState } from "react";
import { useCurrentBreakpoint, useResponsiveFontSize } from "../../../app/hooks/use-responsive-font";
import { makeStyles } from "@mui/styles";

// ----------------------------------------------------------------------

type Props = {
  item: any;
  index: number;
  // selected: boolean;
  // onSelectRow: VoidFunction;
  // onViewRow: VoidFunction;
  // onEditRow: VoidFunction;
  handleUpdateSelectedItem: (index: number, item: any) => void;
  onDeleteRow: (index: number) => void;
};

const useStyles = makeStyles({
  input: {
    '& input[type=number]': {
        '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    }
  },
});

export default function FlatFeeRow({ item, index, handleUpdateSelectedItem, onDeleteRow }: Props) {

  // Text Field Refs
  const descriptionRef = useRef<HTMLInputElement>(null);
  const amountRef = useRef<HTMLInputElement>(null);
  
  const responseiveFontSize = useResponsiveFontSize();
  const classes = useStyles();
  const theme = useTheme();
  
  const { isMDAndUp, isMDAndDown } = useCurrentBreakpoint();

  const [itemDescription, setItemDescription] = useState(
    item?.selectedDescription || ""
  );
  const [taxable, setTaxable] = useState(item?.selectedTaxable || false);
  const [subtotal, setSubtotal] = useState(item?.rowTotal || 0);

  const updateItem = () => {
    item.selectedDescription = itemDescription;
    item.rowTotal = subtotal;
    item.selectedTaxable = taxable;
    handleUpdateSelectedItem(item.listIndex, item);
  };

  useEffect(() => {
    updateItem();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxable]); 

  return (
    <Box
      sx={{
        p: 1,
        borderRadius: 1,
        backgroundColor: alpha(theme.palette.success.lighter, 0.4),
      }}
    >
      <Grid container spacing={{ xs: 2, lg: 1, xl: 2 }} alignItems="center">
        <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
          <Stack direction={"row"} gap={1} alignItems={"center"}>
          <Badge badgeContent={index + 1} color="primary" sx={{mr: 2}}>
              <DragIndicatorIcon />
            </Badge>
            <Typography fontSize={responseiveFontSize}>Fee/Service</Typography>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={3.5}>
          <TextField
            inputRef={descriptionRef}
            fullWidth
            inputProps={{ style: { fontSize: responseiveFontSize } }}
            InputLabelProps={{ style: { fontSize: responseiveFontSize } }}
            size="small"
            label="Description"
            variant="outlined"
            value={itemDescription}
            onChange={(e) => setItemDescription(e.target.value)}
            onBlur={updateItem}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (descriptionRef.current) {
                  descriptionRef.current.blur()
                }
              }
            }}
          />
        </Grid>

        {
          isMDAndUp && (
            <Grid item md={1.5} lg={2.5}/>
          ) 
        }

        <Grid item xs={6} sm={3} md={"auto"} lg={1}>
          <TextField
            inputRef={amountRef}
            className={classes.input}
            inputProps={{ style: { fontSize: responseiveFontSize } }}
            InputLabelProps={{ style: { fontSize: responseiveFontSize } }}
            size="small"
            label="Amount"
            type="number"
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Box sx={{ typography: "subtitle2", color: "text.disabled" }}>
                    $
                  </Box>
                </InputAdornment>
              ),
            }}
            placeholder="0.00"
            value={subtotal === 0 ? "" : subtotal}
            onChange={(e) => setSubtotal(Number(e.target.value))}
            onBlur={updateItem}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (amountRef.current) {
                  amountRef.current.blur()
                }
              }
            }}
          />
        </Grid>

        {/* //MARK: - TAXABLE */}
        <Grid item xs={12} sm={6} md={4} lg={1}>
          <FormControlLabel
            control={
              <Checkbox
                inputProps={{ style: { fontSize: responseiveFontSize } }}
                checked={taxable}
                onChange={(e) => setTaxable(e.target.checked)}
              />
            }
            label={
              <Typography fontSize={responseiveFontSize}>
                {isMDAndDown ? "Taxable" : "Taxed"}
              </Typography>
            }
          />
        </Grid>
        <Grid item xs={0.5}>
          <Typography fontSize={responseiveFontSize}>
            {FormatToLocalCurrency(subtotal)}
          </Typography>
        </Grid>

        <Grid item xs={0.5}>
          <Button
            size="small"
            color="error"
            startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
            onClick={() => onDeleteRow(item.listIndex)}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
