import { DocumentData, QueryDocumentSnapshot, DocumentSnapshot } from '@firebase/firestore-types';
import { firelikeId } from './firelikeid';
import { formatDateOnRead } from '../../time/formatTimezoneOffset';

export const checkTableRowInvoices = (snap: QueryDocumentSnapshot<DocumentData> | DocumentSnapshot<DocumentData>, orgTimezone: string) => {
  if (!snap) return;
  const invoice = snap.data();
  if (!invoice) return;

  const balanceRemaining = invoice.total - (invoice.balanceFulfilled ?? 0);

  const updatedOn = formatDateOnRead({ date: invoice.updatedOn, orgTimezone });
  const eventDate = formatDateOnRead({ date: invoice.eventDate, orgTimezone });
  const createdOn = formatDateOnRead({ date: invoice.createdOn, orgTimezone });
  const rentalDateStart = formatDateOnRead({ date: invoice.rentalDateStart, orgTimezone });
  const rentalDateEnd = formatDateOnRead({ date: invoice.rentalDateEnd, orgTimezone });
  const specifiedReceiveTime = formatDateOnRead({ date: invoice.specifiedReceiveTime, orgTimezone });
  const specifiedReturnTime = formatDateOnRead({ date: invoice.specifiedReturnTime, orgTimezone });

  const invoiceData = {
    id: invoice.id,
    orgId: invoice.orgId ?? '',
    invoiceNumber: invoice.invoiceNumber ?? 0,
    contactPhoneNumber: invoice.contactPhoneNumber ?? '',
    type: invoice.type ?? 'draft',
    tagsQueryHook: invoice.tagsQueryHook ?? [],
    customerDisplayName: invoice.customerDisplayName,
    salesRepName: invoice.salesRepName ?? '',
    updatedOn,
    eventDate,
    createdOn,
    rentalDateStart,
    rentalDateEnd,
    specifiedReceiveTime,
    specifiedReturnTime,
    rentalRange: invoice.rentalRange,
    email: invoice?.email ?? '',
    total: invoice.total,
    balanceRemaining: balanceRemaining,
    balanceFulfilled: invoice.balanceFulfilled ?? 0,
    hasSendRecords: invoice.hasSendRecords ?? false,
    estimateSigned: invoice.estimateSigned ?? false,
    emailDeliveryFailed: invoice.emailDeliveryFailed ?? false,
    isLoaded: invoice.isLoaded ?? false,
    receivedByDelivery: invoice.receivedByDelivery ?? false,
    returnedByPickup: invoice.returnedByPickup ?? false,
    qbId: invoice.qbId ?? null,
    selectedItems: {
      count: invoice.selectedItems?.count,
      items: invoice.selectedItems?.items.map((item: { name: string; selectedQty: number }) => ({
        name: item?.name ?? '',
        selectedQty: item?.selectedQty ?? 0,
      })),
    },
    totalWeight: invoice.totalWeight ?? 0,

    // keyHelp is a FE only helper prop for adding to the table.key prop.
    keyHelp: firelikeId(),
  };

  return invoiceData;
};
