import { sortByNumberValue } from "../../../../utils/sorters/sortBy";

export const GetSubtotalsByInventory = ({
  invoices,
  uniqueInventory,
  filterItemIds,
}) => {

  if (!invoices) return [];

  // Row Types : subtotal | damageWaiver | flatFee | percentFee | label | discount | == | singleItem
  
  const inventoryList = Object.values(uniqueInventory);
  
  let inventoryListIds = inventoryList.map((item) => {
    return {
      inventoryId: item.rowType === "singleItem" ? item?.id : item?.uuid,
      name: item?.rowType === "singleItem" ? item?.name : item?.selectedDescription,
      total: item?.rowTotal ?? 0,
      qty: item?.selectedQty ?? 0,
      categoriesQueryHook: item?.categoriesQueryHook ?? [],
    };
  });

const list = inventoryListIds?.filter((item) => {
  let includeInFiltered = false;

  // If filterItemIds are provided, check if any of them are in categoriesQueryHook
  if (filterItemIds.length > 0) {
    includeInFiltered = filterItemIds.some((filterId) => 
      item.categoriesQueryHook.includes(filterId)
    );
  }

  // If there are no filterItemIds, we only care about items with a non-zero total
  // If there are filterItemIds, we include only those items that are filtered and have a non-zero total
  return (item?.total !== 0 && (filterItemIds.length === 0 || includeInFiltered));
});


  return sortByNumberValue(list, "total");
};
