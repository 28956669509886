import { LoadingButton } from "@mui/lab";
import React, { MouseEvent } from "react";
import { Box, Stack, Dialog, TextField, alpha, IconButton, Typography, Card, Tooltip, Divider, useTheme, Grid, useMediaQuery } from "@mui/material";
import Iconify from "../../../app/iconify";
import { Invoice } from "../../../app/interfaces/invoice";
import { OrganizationData } from "../../../app/interfaces/organizationData";
import { checkSubscriptionLevel } from "../../../app/interfaces/features-set-permissions";
import { useCurrentBreakpoint } from "../../../app/hooks/use-responsive-font";
import { enqueueSnackbar } from "notistack";

interface StickyHeaderProps {
  invoice: Invoice | null | undefined;
  orgData: OrganizationData;
  title: string;
  selectedTags: string[];
  tags: any[];
  invoiceNumbers: {
    hasError: boolean;
    errorLabel: string;
  };
  handleChatClick: (invoice: Invoice) => void;
  handleSaveClick: (event: MouseEvent<HTMLElement>) => void;
  handleNumberChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleConfirmInvoiceChange: () => void;
  handleDuplicate: () => void;
  duplicating: boolean;
  setOpenChangeInvoiceNumber: React.Dispatch<React.SetStateAction<boolean>>;
  makePaymentLink: () => void;
  makingPaymentLink: boolean;
  downloadInvoicePDF: () => void;
  downloadingPDF: boolean;
  setDownloadingPDF: React.Dispatch<React.SetStateAction<boolean>>;
  setShowMakePickList: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenSendEmailDialog: any;
  setShowSyncModal: React.Dispatch<React.SetStateAction<boolean>>;
  openChangeInvoiceNumber: boolean;
  changingInvoice: boolean;
  hasPaymentProcessor: boolean;
  invoiceChangeResult: {
    status: number;
    msg: string;
  } | null;
  confirmDelete: {
    onTrue: () => void;
  };
  confirmCancel: {
    onTrue: () => void;
  };
  showHistoryDialog: {
    onTrue: () => void;
  };
  navigateToPayment: () => void;

  demoMode: boolean;
}

export const StickyHeader: React.FC<StickyHeaderProps> = ({
  invoice,
  orgData,
  title,
  selectedTags,
  tags,
  invoiceNumbers,
  handleChatClick,
  handleSaveClick,
  handleNumberChange,
  handleConfirmInvoiceChange,
  handleDuplicate,
  duplicating,
  setOpenChangeInvoiceNumber,
  makePaymentLink,
  downloadingPDF,
  downloadInvoicePDF,
  setDownloadingPDF,
  setShowMakePickList,
  setOpenSendEmailDialog,
  openChangeInvoiceNumber,
  changingInvoice,
  hasPaymentProcessor,
  makingPaymentLink,
  invoiceChangeResult,
  confirmDelete,
  confirmCancel,
  showHistoryDialog,
  setShowSyncModal,
  navigateToPayment,
  demoMode,
}) => {
  const theme = useTheme();

  const { isMDAndUp, isMDAndDown, isLGAndUp } = useCurrentBreakpoint();


  const chatButton =
    checkSubscriptionLevel("internalNotes", orgData) && invoice ? (
      <Tooltip title="Notes | Files">
        <IconButton
          onClick={() => {
            handleChatClick(invoice);
          }}
        >
          <Iconify
            icon="material-symbols:chat"
            sx={{
              color: theme.palette.grey[600],
              "&:hover": {
                color: theme.palette.primary.main,
              },
            }}
          />
        </IconButton>
      </Tooltip>
    ) : null;

  return (
    <Box
      sx={{
        p: 1,
        mb: 1,
        display: "flex",
        flexDirection: "column", // Stack sections vertically by default
        justifyContent: "space-between",
        borderRadius: 1,
        backgroundColor: (theme) => theme.palette.background.paper,
        border: (theme) => `dashed 1px ${theme.palette.primary.dark}`,
        position: "sticky",
        top: 8, // Adjust the value as needed
        zIndex: 2, // Ensure the sticky element appears above other content
      }}
    >
      <Grid container spacing={2} direction={isMDAndDown ? "column" : "row"}>
        {/* Grid Section 1 */}
        <Grid item xs={12} md={4} alignContent={"center"}>
          <Stack direction={"row"} gap={2} alignItems={"center"}>
            <Typography
              component="a"
              onClick={() => {
                setOpenChangeInvoiceNumber(true);
              }}
              ml={1}
            >
              {title}{" "}
            </Typography>

            {invoiceNumbers.hasError && invoice?.type === "invoice" && orgData.qboRealmId && (
              <div style={{ fontSize: "12px", fontWeight: "500" }}>
                <Stack direction={"row"} alignItems={"center"}>
                  {invoiceNumbers.errorLabel}
                </Stack>
              </div>
            )}

            {orgData.qboRealmId && orgData.qboRealmId !== "" && invoice?.type === "invoice" && (
              <Tooltip title="Sync To QuickBooks: Use this to manually sync invoices, payments and refunds to QuickBooks online if you notice that any of these did not sync automatically when you initially saved them in Adelie.">
                <IconButton
                  onClick={() => {
                    setShowSyncModal(true);
                  }}
                  aria-label="sync-qbo"
                >
                  <Iconify icon="uil:sync" />
                </IconButton>
              </Tooltip>
            )}

            <Dialog
              open={openChangeInvoiceNumber}
              onClose={() => {
                setOpenChangeInvoiceNumber(false);
              }}
            >
              <Card sx={{ p: 3 }}>
                <Stack gap={1} direction={"column"}>
                  <Typography gutterBottom>Change order number?</Typography>

                  <TextField onChange={handleNumberChange} id="standard-basic" label="Enter new order number" />

                  <LoadingButton loading={changingInvoice} onClick={handleConfirmInvoiceChange}>
                    Submit
                  </LoadingButton>

                  <Box
                    sx={{
                      p: 2,
                      width: "100%",
                      borderRadius: 2,
                      bgcolor: (theme) => alpha(theme.palette.warning.light, 0.25),
                      border: (theme) => `dashed 1px ${theme.palette.divider}`,
                    }}
                  >
                    <Stack direction={"row"}>
                      <Typography sx={{ typography: { xs: "body2", xl: "body1" } }} variant="body1" gutterBottom>
                        {`Invoice numbers must be unique across Adelie${
                          orgData.qboRealmId && orgData.qboRealmId !== "" ? " and QuickBooks" : ""
                        }. Upon saving, this invoice number will be checked against existing invoices.`}
                      </Typography>
                    </Stack>
                  </Box>

                  {invoiceChangeResult && (
                    <Box
                      sx={{
                        mt: 2,
                        p: 2,
                        width: "100%", // Make the box fill horizontal space
                        borderRadius: 2,
                        bgcolor: (theme) =>
                          invoiceChangeResult.status === 200 ? alpha(theme.palette.success.light, 0.25) : alpha(theme.palette.error.light, 0.25),
                        border: (theme) => `dashed 1px ${theme.palette.divider}`,
                      }}
                    >
                      <Typography sx={{ typography: { xs: "body2", xl: "body1" } }} variant="body1" gutterBottom>
                        {invoiceChangeResult.msg}
                      </Typography>
                    </Box>
                  )}
                </Stack>
              </Card>
            </Dialog>
          </Stack>
        </Grid>

        {/* Grid Section 2 */}
        <Grid item xs={12} md={4} alignContent={"center"}>
          <Stack direction={"row"} gap={2} alignItems={"center"} justifyContent="flex-start" flexWrap="wrap">
            {selectedTags &&
              selectedTags.length > 0 &&
              selectedTags.map((tagId) => {
                const tag = tags.find((tag) => tag.id === tagId);
                if (tag) {
                  return (
                    <Box
                      sx={{
                        borderRadius: 1,
                        bgcolor: tag.color,
                        color: theme.palette.getContrastText(tag.color),
                        p: 0.5,
                        px: 1,
                        fontSize: 12,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        minWidth: 0,
                      }}
                      key={tag.id}
                    >
                      {tag.name}
                    </Box>
                  );
                } else {
                  return null;
                }
              })}
          </Stack>
        </Grid>

        {/* MARK: ACTIONS*/}
        <Grid item xs={12} md={4}>
          <Stack direction={"row"} justifyContent={"flex-end"}>
            {invoice && (invoice.type === "invoice" || invoice.type === "estimate") && (
              <>
              <Tooltip title="Copy Pay Link">
                <IconButton onClick={makePaymentLink}>
                  <Box
                    sx={{
                      display: "inline-flex",
                      alignItems: "center",
                      cursor: "pointer",
                      "&:hover .iconify": {
                        color: theme.palette.primary.main,
                      },
                    }}
                  >
                    <Iconify icon={ makingPaymentLink ? "line-md:loading-loop" : "heroicons-outline:link"} color={theme.palette.grey[600]} className="iconify" />
                  </Box>{" "}
                </IconButton>
              </Tooltip>
          
                <Tooltip title="Download Invoice">
                <IconButton
                  onClick={downloadInvoicePDF}
                >
                  <Iconify icon={downloadingPDF ? "line-md:downloading-loop" : "mingcute:download-3-fill"} color={theme.palette.grey[600]} />
                </IconButton>
              </Tooltip>
             
           
            <Tooltip title="Pick List">
              <IconButton
                onClick={() => {
                  setShowMakePickList(true);
                }}
                aria-label="notifications"
              >
                <Iconify icon="material-symbols:inventory-rounded" />
              </IconButton>
            </Tooltip>

            <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />

                <Tooltip title="Send Email">
                <IconButton
                  onClick={setOpenSendEmailDialog}
                  sx={{
                    color: theme.palette.grey[600],
                    "&:hover": {
                      color: theme.palette.info.main,
                    },
                  }}
                >
                  <Iconify icon="ic:round-send" />
                </IconButton>
              </Tooltip>
          
                      
          
<Tooltip title="Receive Payment">
              <IconButton
                onClick={navigateToPayment}
                sx={{
                  color: theme.palette.grey[600],
                  "&:hover": {
                    color: theme.palette.success.main,
                  },
                }}
              >
                <Iconify icon="wpf:bank-cards" />
              </IconButton>
            </Tooltip>
              
            

            {chatButton}

       
                <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />

                </>
              )
            }

            <Tooltip title="Save">
              <IconButton
                onClick={handleSaveClick}
                sx={{
                  color: theme.palette.grey[600],
                  "&:hover": {
                    color: theme.palette.success.main,
                  },
                }}
              >
                <Iconify icon="ic:round-save" />
              </IconButton>
            </Tooltip>

{
  invoice && (
    <>
 <Tooltip title="Duplicate This Order">
              <IconButton
                onClick={handleDuplicate}
                sx={{
                  color: theme.palette.grey[600],
                  "&:hover": {
                    color: theme.palette.info.main,
                  },
                }}
              >
                <Iconify icon={duplicating ? "line-md:loading-twotone-loop" : "ion:duplicate"} />
              </IconButton>
            </Tooltip>

            <Tooltip title="History">
              <IconButton
                onClick={() => {
                  if (demoMode) {
                    enqueueSnackbar("This feature is disabled in demo mode.", { variant: "info" });
                    return;
                  }
                  
                  showHistoryDialog.onTrue();
                }}
                sx={{
                  color: theme.palette.grey[600],
                  "&:hover": {
                    color: theme.palette.success.main,
                  },
                }}
              >
                <Iconify icon="material-symbols:history-rounded" />
              </IconButton>
            </Tooltip>

            {/* <Tooltip title="Cancel Order">
              <IconButton
                onClick={() => {
                  confirmCancel.onTrue();
                }}
                sx={{
                  color: theme.palette.grey[600],
                  "&:hover": {
                    color: theme.palette.warning.main,
                  },
                }}
              >
                <Iconify icon="ic:round-cancel" />
              </IconButton>
            </Tooltip> */}

            <Tooltip title="Delete Order">
              <IconButton
                onClick={() => {

                  if (demoMode) {
                    enqueueSnackbar("This feature is disabled in demo mode.", { variant: "info" });
                    return;
                  }

                  confirmDelete.onTrue();
                }}
                sx={{
                  color: theme.palette.grey[600],
                  "&:hover": {
                    color: theme.palette.error.main,
                  },
                }}
              >
                <Iconify icon="solar:trash-bin-trash-bold" />
              </IconButton>
            </Tooltip>
    </>
  )
}
           
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};
