import React, { useEffect, useRef, useState } from "react";

import { useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";

import { emailInvoice } from "../../../app/services/cloud-functions/emailInvoice";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import { INVOICE, INVOICE_ESTIMATE } from "../../../app/utils/models/modelConstants/modelConstants";
import { emailSettingsQuery } from "../../../app/services/firestore/queries/orgQueries";
import { authSelector } from "../../auth/authSlice";
import { INVOICES_COLLECTION, SEND_RECORDS } from "../../../app/utils/models/collections/collectionConstants";
import { dollarToCents } from "../../../app/utils/models/configure/configureCurrencyToCents";
import InvoicePDF from "./invoice-pdf";
import { PDFViewer } from "@react-pdf/renderer";
import {
  AppBar,
  Box,
  Card,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  Stack,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useBoolean } from "../../../app/hooks/use-boolean";
import { fileData } from "../../file-manager/file-thumbnail";
import Iconify from "../../../app/iconify/iconify";
import { LoadingButton } from "@mui/lab";
import FileManagerNewFileBox from "../../file-manager/file-manager-new-file-box";
import { COLOR_TEXT_GRAY_1 } from "../../../app/ui/colorConstants";
import ReactQuill from "react-quill";
import { useOneOffContext } from "../../../app/global-context/OneOffContext";
import { useResponsiveFontSize } from "../../../app/hooks/use-responsive-font";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SendInvoiceModal = ({
  open,
  invoice,
  customer,
  settingsId,
  venues,
  invoiceTextData,
  i18n,
  logoData,
  itemImages,
  files,
  signatureData,
  sendType = "invoice", // estimate | invoice | signatureRequest
  isNew,
  setOpenSendEmailDialog,
  emailEstimateSettings,
  emailInvoiceSettings,
}) => {


  const selectFileRef = useRef<HTMLInputElement>(null);

  const theme = useTheme();
  const { userData, orgData, fsOrgPrefix } = useSelector(authSelector);
  const { getVenueSpecificSettings } = useOneOffContext();
  const responsiveFontSize = useResponsiveFontSize();

  const firebase = useFirebase();
  const firestore = useFirestore();
  const history = useHistory();
  const upload = useBoolean();

  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false)
  const [emailSettings, setEmailSettings] = useState(null);
  const [emailRecipients, setEmailRecipients] = useState("");

  const [emailSubject, setEmailSubject] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  const [pdfOrgData, setPdfOrgData] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedOption, setSelectedOption] = useState(''); // Set initial value to empty string
  const [selectedFileOption, setSelectedFileOption] = useState(''); 
  const [newAttachments, setNewAttachments] = useState([]);

  //onlinePayments Checkbox Toggle Settings
  const [includePaymentLinkChecked, setIncludePaymentLinkCheckedChecked] = useState(false);
  const [useDefaultDeposit, setUseDefaultDeposit] = useState(false);

  const [disabled, setDisabled] = useState(true);

  const toggleChecked = () => {
    setIncludePaymentLinkCheckedChecked(!includePaymentLinkChecked);
  };
  const toggleDisable = () => {
    setDisabled(!disabled);
  };
  const onlinePaymentsChecked = (e) => {
    setIncludePaymentLinkCheckedChecked(e.target.checked);
    toggleDisable();
  };

  const handleAddEmail = (e) => {
    const email = e.target.value;
    const newRecipients = emailRecipients ? `${emailRecipients},${email}` : email;
    setEmailRecipients(newRecipients);
    setSelectedOption(''); // Reset selection after adding
  };

  const balanceRemaining = Math.round(invoice?.balanceRemaining * 100) / 100;
  const stripeAccount = orgData?.stripeAccount;
  const justifiAccount = orgData?.justifiSubAccount;
  const [paymentAmt, setPaymentAmt] = useState(0);

  const handleClose = () => {
    setSelectedFiles([]);
    setOpenSendEmailDialog(false);
  };

  useEffect(() => {
    const fetchEmailSettings = async () => {

      let pdfEmailSettings = sendType === "invoice" ? emailInvoiceSettings : emailEstimateSettings;
      let pdfOrgData = { ...orgData };

      // PSR SPECIFIC
      const psr = process.env.REACT_APP_PSR_ID;
      if (orgData.id === psr && ((invoice.receiveVenueQueryHook && invoice.receiveVenueQueryHook !== "") || (invoice.returnVenueQueryHook && invoice.returnVenueQueryHook !== ""))) {
        const psrSettings = await getVenueSpecificSettings(invoice.receiveVenueQueryHook)
        if (psrSettings) {
          pdfEmailSettings = invoice.type === "invoice" ? psrSettings.invoiceSettings : psrSettings.estimateSettings;
          pdfOrgData.logoUrl = psrSettings.logo;
        }
      }
      // >>>>>>>>>>>>>>>>

      setPdfOrgData(pdfOrgData);

      setLoading(false);
      
      if (pdfEmailSettings) {
        setEmailSettings(pdfEmailSettings);
        setEmailRecipients(customer?.email ?? null);
        setEmailBody(pdfEmailSettings?.emailBody);
        setEmailSubject(pdfEmailSettings?.emailSubject);
      }
    }

    fetchEmailSettings();
      
    // >>>>>>>>>>>>>>>>
  }, [sendType])

  useEffect(() => {
    if (!orgData) return;
    setLoading(true);
    setPaymentAmt(balanceRemaining);
  }, [firestore, fsOrgPrefix]);

  // MARK: - Attachment
  const handleAddAttachment = (event) => {

    if (!event) {
      return;
    }
    const value = event.target.value

    if (selectedFiles.includes(value)) {
      return;
    }
    const newFiles = [...selectedFiles, value];
    setSelectedFiles(newFiles);
    setSelectedFileOption("");

  };

  const handleRemoveAttachment = (removedFileId) => {
    const newFiles = selectedFiles.filter((tagId) => tagId !== removedFileId);
    setSelectedFiles(newFiles);
  };

  // MARK: - Upload Files
  const handleUpload = async () => {
    const storageRef = firebase.storage().ref();
    const firestoreRefs = []; // Array to store Firestore document references

    const uploadBlob = async (file) => {
      const { key, name = "", size = 0, type = "doc" } = fileData(file);

      const response = await fetch(file.preview);
      const blob = await response.blob();
      const fileRef = storageRef.child(`filemanager/${orgData.id}/${file.name}`);
      const snapshot = await fileRef.put(blob);

      const downloadURL = await snapshot.ref.getDownloadURL();

      // Create a Firestore document for each file after it's uploaded
      const firestoreRef = firebase.firestore().collection("orgs").doc(orgData.id).collection("fileManager").doc();

      const newDoc = {
        id: firestoreRef.id,
        name: name,
        size,
        type,
        url: downloadURL,
        tags: [],
        isFavorited: false,
        shared: null,
        createdAt: new Date(),
        modifiedAt: new Date(),
      };

      await firestoreRef.set(newDoc);

      firestoreRefs.push(firestoreRef.id); // Store the reference
    };

    await Promise.all(
      newAttachments.map((fileObj) => {
        if (!(fileObj instanceof File)) return null;
        return uploadBlob(fileObj);
      })
    )
      .then(() => {
        console.info("All files uploaded and documents created");
      })
      .catch((error) => {
        console.error("Error uploading files or adding document: ", error);
      });

    return firestoreRefs; // Return the array of Firestore document references
  };


  // MARK: - Validate Emails
  const validateEmails = (emails) => {
    const invalidEmails = [];
    const commonMisspellings = [
      { wrong: 'gmail,com', correct: 'gmail.com' },
      { wrong: 'yahoo,com', correct: 'yahoo.com' },
      { wrong: 'hotmail,com', correct: 'hotmail.com' },
      { wrong: 'outlook,com', correct: 'outlook.com' },
      // Add more common misspellings as needed
    ];
  
    emails.forEach((email) => {
      // Check if the email is in a valid format using a simple regex
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        invalidEmails.push(email);
      } else {
        // Check for common misspellings
        commonMisspellings.forEach(({ wrong, correct }) => {
          if (email.includes(wrong)) {
            invalidEmails.push(`${email} (Did you mean ${email.replace(wrong, correct)}?)`);
          }
        });
      }
    });
  
    if (invalidEmails.length > 0) {
      return `The following emails are invalid:\n${invalidEmails.join('\n')}`;
    } else {
      return null; // Return null if all emails are valid
    }
  };
  


  // MARK: - On Send
  const sendEmail = async () => {

    // First let's validate the emails.
    const recipients = emailRecipients
      .toString()
      .trim()
      .split(",")
      .map((e) => e.trim());

    const validationMessage = validateEmails(recipients);

    if (validationMessage) {
      enqueueSnackbar(validationMessage, { variant: "error" });
      setErrorMessage(validationMessage);
      return;
    }

    setSending(true);

    let attachmentIds = selectedFiles;

    // First check if there are attachments that need to be uploaded.
    if (newAttachments.length > 0) {
      // If there are, upload them.
      const newAttachmentIds = await handleUpload();
      attachmentIds = [...attachmentIds, ...newAttachmentIds];
    }

    const replyToEmail = orgData?.useAdminEmailReply ? orgData?.adminEmail : userData?.email ?? null;
    const name = `${userData?.firstName} ${userData?.lastName}`;

    const sendRecordPayload = {
      createdOn: new Date(),
      createdBy: userData.id,
      paymentAmt: includePaymentLinkChecked ? dollarToCents(paymentAmt) : dollarToCents(balanceRemaining),
      sendPaymentLink: includePaymentLinkChecked,
      attachments: attachmentIds,
    };

    const ref = firestore.collection(`${fsOrgPrefix}${INVOICES_COLLECTION}/${invoice?.id}/${SEND_RECORDS}`).doc();

    await ref.set({
      id: ref.id,
      ...sendRecordPayload,
    });

    let finalSettingsId = sendType === "invoice" ? "invoiceEmailSettings" : "emailEstimateSettings";

    try {
      const emailPayload =  {
        sendType: sendType,
        settingsId: finalSettingsId,
        orgId: orgData?.id,
        invoiceId: invoice?.id,
        emails: recipients,
        subjectLine: emailSubject,
        emailBody: emailBody,
        replyTo: {
          email: replyToEmail,
          name,
        },
        sendPaymentLink: includePaymentLinkChecked,
        sendRecordId: ref.id,
      }

      await emailInvoice(
        { firebase },
        emailPayload
      );

      enqueueSnackbar(invoice?.type === INVOICE ? "Invoice sent" : "Estimate sent", { variant: "success" });
      setSending(false)
      setOpenSendEmailDialog(false)
    } catch (err) {
      console.log(err);
      enqueueSnackbar("Something went wrong, please try again later.", { variant: "error" });
    }
  };

  // MARK: - Render
  return (
    <Dialog fullWidth maxWidth={"xl"} open={open} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose}>
            <Iconify icon="carbon:close-filled" />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {sendType === "signatureRequest" ? "Send Signature Request" : invoice?.type === INVOICE ? "Send Invoice" : "Send Estimate"}
          </Typography>

          <LoadingButton
            onClick={sendEmail}
            loading={sending}
            // disabled={loading}
            variant="contained"
            sx={{
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.primary.lighter,
            }}
          >
            {sending ? "Sending..." : "Send"}
          </LoadingButton>
        </Toolbar>
      </AppBar>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} className="send-invoice-email-fields">
            <Box component="form" noValidate autoComplete="off">
              <Typography variant="body2" gutterBottom fontSize={responsiveFontSize}>
                Customer: {customer?.customerDisplayName}. <br />
                You can CC more emails by comma separation, or select from customers' additional contacts.
              </Typography>

              <FormControl fullWidth margin="normal">
                <TextField
                  error={errorMessage !== null}
                  inputProps={{
                    sx: {
                      fontSize: responsiveFontSize,
                    },
                  }}
                  InputLabelProps={{
                    style: { fontSize: responsiveFontSize },
                  }}
                  size="small"
                  placeholder="email"
                  value={emailRecipients}
                  onChange={(e) => {
                    setEmailRecipients(e.target.value);
                    if (errorMessage) {
                      setErrorMessage(null);
                    }
                  }}
                />
              </FormControl>
              {errorMessage && (
                <Typography variant="body2" gutterBottom color={"red"}>
                  {errorMessage}
                </Typography>
              )}

              {customer && customer.additionalContacts && customer.additionalContacts.length > 0 && (
                <>
                  <Stack mt={2} direction="row" alignItems="center" spacing={1}>
                    <FormControl
                      sx={{
                        width: "200px",
                      }}
                    >
                      <InputLabel>Add more emails...</InputLabel>

                      <Select size="small" value={selectedOption} onChange={handleAddEmail} renderValue={(selected) => selected}>
                        {customer.additionalContacts
                          .filter((c) => c.email)
                          .map((c) => (
                            <MenuItem value={c.email} key={c.id}>
                              {c.email}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>

                    <Tooltip title="Add email from customer's additional contacts">
                      <Iconify icon="fluent:question-circle-12-filled" style={{ color: COLOR_TEXT_GRAY_1 }} />
                    </Tooltip>
                  </Stack>
                  <br />
                </>
              )}

              <FormControl fullWidth margin="normal">
                <TextField
                  inputProps={{
                    sx: {
                      fontSize: responsiveFontSize,
                    },
                  }}
                  InputLabelProps={{
                    style: { fontSize: responsiveFontSize },
                  }}
                  size="small"
                  label="Email Subject"
                  placeholder="Subject"
                  required
                  defaultValue={emailSubject}
                  onChange={(e) => setEmailSubject(e.target.value)}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <ReactQuill
                  value={emailBody}
                  onChange={setEmailBody}
                  modules={{
                    toolbar: [["bold", "italic", "underline"], ["link"]],
                  }}
                  placeholder="Email Body"
                  theme="snow"
                  style={{
                    height: "100px",
                    marginBottom: "24px",
                  }}
                />
              </FormControl>

              <br />

              <Stack
                direction="column"
                gap={2}
                sx={{
                  display: sendType === "signatureRequest" || (!stripeAccount && !justifiAccount) ? "none" : "flex",
                }}
              >
                <FormControlLabel
                  sx={{
                    mt: 2,
                  }}
                  control={<Checkbox checked={includePaymentLinkChecked} onChange={onlinePaymentsChecked} />}
                  label=" Include a payment link with this email?"
                />

                {!disabled && (
                  <>
                    <TextField
                      sx={{
                        width: "150px",
                      }}
                      inputProps={{
                        sx: {
                          fontSize: responsiveFontSize,
                        },
                      }}
                      InputLabelProps={{
                        style: { fontSize: responsiveFontSize },
                      }}
                      size="small"
                      label="Payment Amount"
                      value={paymentAmt}
                      onChange={(e) => setPaymentAmt(e.target.value)}
                      disabled={disabled}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />

                    <FormControlLabel
                      sx={{
                        mt: 1,
                      }}
                      control={
                        <Checkbox
                          defaultChecked={false}
                          checked={useDefaultDeposit}
                          onChange={(e) => {
                            setUseDefaultDeposit(e.target.checked);
                            const checked = e.target.checked;
                            if (checked) {
                              const depositAmount = (balanceRemaining * (orgData.defaultDepositPercent / 100)).toFixed(2);
                              setPaymentAmt(depositAmount);
                            } else {
                              setPaymentAmt(balanceRemaining);
                            }
                          }}
                        />
                      }
                      label={`  Apply default security deposit amount: ${orgData.defaultDepositPercent}%`}
                    />
                  </>
                )}

                {invoice?.type === INVOICE_ESTIMATE && !orgData.automaticallyConvertEstimate && (
                  <div>
                    <br></br>
                    Note: Estimates will not automatically convert to Invoices until payments are successful. ACH payments may take several days, which means
                    the inventory will not be considered rented until the payment is finalized.
                  </div>
                )}
              </Stack>

              <div>
                <br></br>

                <Stack direction={"row"}>
                  <Tooltip
                    sx={{ mr: 1 }}
                    title={`Emails have a 10MB attachment limit. Priority is given
                        to the Invoice PDF, then attachments. If the total size of the
                        attachments exceeds 10MB, the remaining attachments will be
                        added to the email as links that can be viewed and downloaded
                        from the browser by the customer. Do not add any attachment that
                        you would not want generally available to the public`}
                  >
                    <Iconify icon="fluent:question-circle-12-filled" style={{ color: COLOR_TEXT_GRAY_1 }} />
                  </Tooltip>

                  <Typography fontSize={responsiveFontSize}>
                    You can add attachments to this email from your saved files in the File Manager, or add a new file by clicking the "Upload" button.
                  </Typography>
                </Stack>

                <br />
                <FormControl fullWidth>
                  <InputLabel>Add attachment...</InputLabel>
                  <Select
                    inputRef={selectFileRef}
                    value={selectedFileOption}
                    onChange={handleAddAttachment}
                    renderValue={(selected) => {
                      const selectedFile = files.find((t) => t.id === selected);
                      return selectedFile ? selectedFile.name : "";
                    }}
                    MenuProps={{
                      autoFocus: false,
                      disableAutoFocusItem: true,
                      disableAutoFocus: true,
                    }}
                  >
                    {files &&
                      files
                        .filter((t) => !selectedFiles.includes(t.id))
                        .map((tag) => (
                          <MenuItem value={tag.id} key={tag.id}>
                            {tag.name}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>

                <div style={{ marginTop: "16px", marginBottom: "16px" }}>
                  {selectedFiles &&
                    selectedFiles.length > 0 &&
                    selectedFiles.map((fileId) => {
                      const file = files.find((file) => file.id === fileId);

                      if (file) {
                        return <Chip sx={{ mr: 1 }} label={file.name} key={file.id} onDelete={() => handleRemoveAttachment(fileId)} />;
                      } else {
                        return null;
                      }
                    })}
                </div>

                <FileManagerNewFileBox onNewAttachments={setNewAttachments} />
              </div>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} style={{ paddingLeft: "12px" }} className="invoice-pdf-preview">
            <Card
              sx={{
                overflowY: "scroll",
                height: "70vh",
                width: "100%",
                boxShadow: "0px 4px 16px rgba(26, 26, 53, 0.15)",
                position: "relative",
              }}
            >
              {emailSettings && invoice && pdfOrgData && (
                <Box
                  sx={{
                    position: "absolute",
                    border: 0,
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <PDFViewer showToolbar={true} style={{ height: "100%", width: "100%" }}>
                    <InvoicePDF
                      invoice={invoice}
                      orgData={pdfOrgData}
                      venues={venues}
                      emailSettings={emailSettings}
                      customText={invoiceTextData}
                      i18n={i18n}
                      logoData={logoData}
                      itemImages={itemImages}
                      signatureData={signatureData}
                    />
                  </PDFViewer>
                </Box>
              )}
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default SendInvoiceModal;
