import { useEffect, useState, useRef } from "react";
import { useInvoicesInRange } from "./fetch/useInvoicesInRange";
import moment from "moment";
import workerFactory from "workerize-loader!../workers/workAvailableInvInvoice.js"; // eslint-disable-line import/no-webpack-loader-syntax


export function useAvailableInvInvoice({ startEndOps, inventory, invoiceItems, initialInvoice }) {

  const optsStart = startEndOps?.rentalDateStart ?? null;
  const optsEnd = startEndOps?.rentalDateEnd ?? null;

  const stock = useInvoicesInRange(
    {
      useTurnaround: true,
      rentalDateStart: optsStart ?? new Date(),
      rentalDateEnd: optsEnd ?? new Date(),
    },
    inventory ?? [],
    initialInvoice?.id ? [initialInvoice.id] : []
  );

  const [availableStock, setAvailableStock] = useState({});
  const [showOverbookedError, setShowOverbookedError] = useState(false);

  // Use refs to track if we've already calculated for the current data
  const lastCalculationRef = useRef({
    stockData: null,
    invoiceItems: null,
    inventory: null,
  });

  useEffect(() => {
    stock.refresh();
  }, [optsStart, optsEnd, inventory]);

  useEffect(() => {

    if (stock.fetching || !inventory) return;

    const currentData = {
      stockData: stock.data,
      invoiceItems,
      inventory,
    };

    // Check if we need to recalculate
    // const needsRecalculation = JSON.stringify(currentData) !== JSON.stringify(lastCalculationRef.current);
    const needsRecalculation = true;

    if (needsRecalculation) {
      const worker = workerFactory();
      worker
        .calculateAvailableStock(invoiceItems, stock.data)
        .then((result) => {
          console.log("Worker result:", result);
          setAvailableStock(result.availableStock);
          setShowOverbookedError(result.showOverbookedError);
          // Update the last calculation reference
          lastCalculationRef.current = currentData;
        })
        .catch((error) => {
          console.error("Worker error:", error);
        })
        .finally(() => {
          worker.terminate();
        });
    }
  }, [stock.data, invoiceItems, inventory, stock.fetching, optsStart, optsEnd]);

  return {
    state: { availableStock, showOverbookedError },
    // actions: {},
  };
}
