import { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';

import { Button, CircularProgress, Container, Divider, Stack, Table, Typography } from "@mui/material";
import { getFirebase, useFirestore } from "react-redux-firebase";

import { OrganizationData } from "../../app/interfaces/organizationData";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { functions } from "../../app/config/firebase";
import { InventoryItem } from "../../app/interfaces/inventoryItem";
import { checkInventory } from "../../app/utils/models/checkers/checkInventory";
// Define the prop type for orgData
interface SyncReportProps {
    orgData: OrganizationData;
  }

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });

export default function InventorySyncReport({ orgData }: SyncReportProps) {
  const classes = useStyles();

  const firestore = useFirestore();
  const firebase = getFirebase();

  const [badInventory, setBadInventory] = useState<InventoryItem[] | null>(null);
  const [loadingInitial, setLoadingInitial] = useState(false);
  const [loadingInitialComplete, setLoadingInitialComplete] = useState(false);

  interface InventoryStatus {
    processing: boolean;
    success: boolean;
    error: {
      Message: string;
      Detail: string;
      Code: string;
    } | null;
  }

  const [processingStatus, setProcessingStatus] = useState<{
    [inventoryId: string]: InventoryStatus;
  }>({});

  useEffect(() => {
    if (orgData) {
      setLoadingInitial(true);

      const loadInventory = async () => {
        const inventoryQuery = await firestore
          .collection("orgs")
          .doc(orgData.id)
          .collection("inventory")
          .where("qbId", "==", null)
          .get();

        const inventoryItems = inventoryQuery.docs.map(
          (snap) => checkInventory(snap) as InventoryItem
        );

        setBadInventory(inventoryItems);
        setLoadingInitial(false);
        setLoadingInitialComplete(true);
      };

      loadInventory();
    }
  }, [orgData]);

  // Function to trigger cloud function for a specific item
  const syncInventoryToQuickBooks = async (index: number) => {
    const item = badInventory && badInventory[index];
    if (!item) {
      return;
    }

    // Update the processing status for this item to "Processing"
    setProcessingStatus((prevProcessingStatus) => ({
      ...prevProcessingStatus,
      [item.id]: {
        processing: true,
        success: false,
        error: null,
      },
    }));

    // Call your cloud function here for the item at the given index
    try {
      // Perform the cloud function call
      const call = functions.httpsCallable("manualInventorySync");

      return call({
        orgId: orgData.id,
        itemId: item.id,
      })
        .then(async (res: any) => {
          console.log(res);
          const status = res.data.status;
          if (status === 200) {
            setProcessingStatus((prevProcessingStatus) => ({
              ...prevProcessingStatus,
              [item.id]: {
                processing: false,
                success: true,
                error: null,
              },
            }));
          } else if (status === 500) {
            const errorDataArray = res.data.error.Fault.Error;
            const errorData = errorDataArray[0];

            setProcessingStatus((prevProcessingStatus) => ({
              ...prevProcessingStatus,
              [item.id]: {
                processing: false,
                success: false,
                error: errorData || {
                  Message: "Sync Failed",
                  Detail:
                    "Sorry, we do not know why it failed. Please contact support.",
                  Code: "ERROR_CODE",
                },
              },
            }));
          }
        })
        .catch((err: any) => {
          console.log(err);
        });

      // If the cloud function call is successful, update the status to "Success"
    } catch (error) {
      // If there is an error, update the status to "Failure"
    }
  };

  // Sync all items.
  const syncAllInventory = async () => {
    if (!badInventory || badInventory?.length === 0) {
      return;
    }

    const updatedProcessingStatus = { ...processingStatus }; // Create a copy to update

    for (let index = 0; index < badInventory.length; index++) {
      const item = badInventory[index];

      // Update the processing status for this item to indicate processing.
      updatedProcessingStatus[item.id] = {
        processing: true,
        success: false,
        error: null,
      };
      setProcessingStatus(updatedProcessingStatus);
    }

    for (let index = 0; index < badInventory.length; index++) {
      await syncInventoryToQuickBooks(index)
    }

  };

  return (
    <>
      {loadingInitial && <CircularProgress />}

      {loadingInitialComplete && badInventory && (
        <>
          <Divider />
          <br />

          <Stack spacing={2} alignItems={"center"} direction={"row"}>
            <Typography color={"darkred"}>You have {badInventory?.length} inventory items that are not synced to your QuickBooks account.</Typography>

            <Button disabled={true} variant="contained" color="primary" onClick={() => syncAllInventory()}>
              Sync All
            </Button>
          </Stack>

          <br />

          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell width={200}>Name</TableCell>
                  <TableCell align="left">Description</TableCell>
                  <TableCell align="left">Sync</TableCell>
                  <TableCell align="right">Result</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {badInventory.map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>

                    <TableCell align="left">{row.description}</TableCell>

                    <TableCell align="right">{row.notes}</TableCell>

                    <TableCell align="left">
                      {processingStatus[row.id]?.processing ? (
                        <Button disabled>Processing...</Button>
                      ) : processingStatus[row.id]?.success ? (
                        <Button disabled>Synced</Button>
                      ) : (
                        <Button variant="contained" color="primary" onClick={() => syncInventoryToQuickBooks(index)}>
                          Sync to QuickBooks
                        </Button>
                      )}
                    </TableCell>

                    <TableCell align="right">
                      {processingStatus[row.id]?.error ? (
                        <Stack direction={"column"}>
                          <Typography color={"darkred"}>{processingStatus[row.id]?.error?.Message}</Typography>
                          <Typography color={"darkred"}>{processingStatus[row.id]?.error?.Detail}</Typography>
                        </Stack>
                      ) : processingStatus[row.id]?.success ? (
                        <Typography color={"darkgreen"}>SUCCESS!</Typography>
                      ) : (
                        <>--</>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}