import { CheckSelectedItem } from "../../../../utils/models/checkers/checkSelectedItem";
import { FormatForUsageChart } from "./formatForUsageChart";
import { TimeMachine } from "../../../../utils/time/timeMachine";
import { sortByNumberValue } from "../../../../utils/sorters/sortBy";
import moment from "moment";
import { GetUsageProRateWindow } from "./getUsageProRateWindow";

export const GetUsageByInventory = ({
  invoices,
  inventoryIds,
  inventory,
  dateRange,
  chartSort,
  filterItemIds,
}) => {
  if (!invoices || !inventoryIds || !inventory) return [];
  const start = new moment(dateRange?.start);
  const end = new moment(dateRange?.end);
  const diff = end.diff(start, "hours");
  let inventoryListIds = inventoryIds.map((id) => {
    const inv = inventory?.find((c) => c?.id === id);
    const potential = inv?.stock ?? 0;
    return {
      inventoryId: id,
      name: inv?.name ?? "",
      usage: 0,
      overflow: 0,
      maxPotential: diff ? (diff + 1) * potential : 0, // adding 1 hour because moment rounds down
    };
  });
  invoices?.map((invoice) => {
    const selectedItems = invoice?.selectedItems?.items ?? null;
    const maxHours = GetUsageProRateWindow({
      invoiceStart: TimeMachine(invoice?.rentalDateStart).moment,
      invoiceEnd: TimeMachine(invoice?.rentalDateEnd).moment,
      selectedStart: start,
      selectedEnd: end,
    });
    selectedItems &&
      selectedItems.map((item) => {
        const safeItem = CheckSelectedItem(item);
        // check for category
        const categoryMatch = (catIds, item) => {
          let match = false;
          if (catIds?.length === 0) return true;
          catIds.map((id) => {
            if (match) return;
            if (item?.categoriesQueryHook?.includes(id)) {
              match = true;
            }
          });
          return match;
        };
        if (!categoryMatch(filterItemIds, safeItem)) return inventoryListIds;
        const inList = inventoryListIds.find(
          (i) => i?.inventoryId === item?.id
        );
        if (!inList) return inventoryListIds;
        inventoryListIds = inventoryListIds?.map((i) => {
          if (i?.inventoryId !== item?.id) return i;
          const updateUsageAmt = FormatForUsageChart({
            item: safeItem,
            inventoryListItem: i,
            maxHours,
          });
          return {
            inventoryId: i?.inventoryId,
            name: i?.name,
            usage: i?.usage + updateUsageAmt.eligibleHours,
            overflow: 0,
            maxPotential: i?.maxPotential,
          };
        });
      });
  });
  const list = inventoryListIds
    ?.filter((item) => {
      return item?.usage !== 0;
    })
    .map((i) => {
      const { overflow, usage, maxPotential, ...rest } = i;
      const of = usage - maxPotential;
      const newMax = maxPotential - usage;
      const newUsage = usage > maxPotential ? maxPotential : usage;

      return {
        overflow: of > 0 ? of : 0,
        usage: newUsage,
        maxPotential: newMax > 0 ? newMax : 0,
        ...rest,
      };
    });

  const highestUsage = sortByNumberValue(list, "usage")?.[0]?.usage ?? 0;
  const highestOverflow =
    sortByNumberValue(list, "overflow")?.[0]?.overflow ?? 0;
  const highestMaxPotential =
    sortByNumberValue(list, "maxPotential")?.[0]?.maxPotential ?? 0;

  const newList = list?.map((li) => {
    return {
      usagePercent: li?.usage / highestUsage,
      overflowPercent: li?.overflow / highestOverflow,
      maxPotentialPercent: li?.maxPotential / highestMaxPotential,
      ...li,
    };
  });
  return sortByNumberValue(newList, chartSort);
};
