import { Box, Card, Grid, Paper, Stack, Typography, Link as MuiLink } from '@mui/material'
import { DateRange, PickersShortcutsItem } from '@mui/x-date-pickers-pro';
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { endOfDay, format, startOfDay, subDays } from 'date-fns';
import { Link } from 'react-router-dom';

import React, { useState } from 'react'
import { Payment } from '../../../app/interfaces/payment';
import { useFirestore } from 'react-redux-firebase';
import { FormatToLocalCurrency } from '../../../app/utils/currency/formatToLocalCurrency';
import { fCurrency } from './components/format-number';
import { DataGridPro, GridColDef, GridToolbar, gridClasses } from '@mui/x-data-grid-pro';
import { ROUTE_EDIT_INVOICE } from '../../../app/routes';
import { useSelector } from 'react-redux';
import { authSelector } from '../../auth/authSlice';

const RevenueReport = () => {

    const firestore = useFirestore();
    const { orgData } = useSelector(authSelector)
    
    const [payments, setPayments] = React.useState<Payment[]>([]);
    const [totalTax, setTotalTax] = React.useState<number>(0);
    const [totalIncome, setTotalIncome] = React.useState<number>(0);

    const [revenueTotalString, setRevenueTotalString] = React.useState<string>("0");
    const [taxTotalString, setTaxTotalString] = React.useState<string>("0");
    const [incomeTotalString, setIncomeTaxTotalString] = React.useState<string>("0");

    const [dateRange, setDateRange] = useState({
        start: startOfDay(subDays(new Date(), 7)),
        end: endOfDay(new Date()),
    });

    const fetchPayments = async () => {
      const payments = await firestore
        .collection("orgs")
        .doc(orgData.id)
        .collection("payments")
        .where("paymentDate", ">=", dateRange.start)
        .where("paymentDate", "<=", dateRange.end)
        .orderBy("paymentDate", "desc")
        .get();
        
      let paymentsData = payments.docs.map((doc) => {
        return { id: doc.id, ...doc.data() } as Payment;
      });

      paymentsData = paymentsData.filter(
        (payment) => payment.status === "complete"
      );

      // Calculate tax rollup
      let totalTaxForPeriod = 0;
      paymentsData.forEach((payment) => {
        if (!payment.invoices) return;
        const taxAmt = payment.invoices.reduce(
          (acc, invoice) => {
            const tax = Number(invoice.paymentApplicableTaxAmt);
            return acc + (isNaN(tax) ? 0 : tax);
          },
          0
        );
        totalTaxForPeriod += taxAmt;
      });

      setPayments(paymentsData);
      const total = paymentsData.reduce((acc, payment) => acc + payment.total, 0);
      const totalIncome = total - totalTaxForPeriod;
      setTotalTax(totalTaxForPeriod);
      setTotalIncome(totalIncome);
    };

    // Call fetchPayments when dateRange changes
    React.useEffect(() => {
        if (!orgData) return;
        fetchPayments();
    }, [dateRange]);

    // Calculate total of payments when payments change
    React.useEffect(() => {
        const total = payments.reduce((acc, payment) => acc + payment.total, 0);
        setRevenueTotalString(FormatToLocalCurrency(total / 100, orgData));
    }, [payments]);

     // Calculate total of tax when payments change
     React.useEffect(() => {
      setTaxTotalString(FormatToLocalCurrency(totalTax / 100, orgData));
  }, [totalTax]);
     // Calculate total of tax when payments change
     React.useEffect(() => {
      setIncomeTaxTotalString(FormatToLocalCurrency(totalIncome / 100, orgData));
  }, [totalIncome]);

    const handleDateRangeChange = (newDates: DateRange<any>) => {
        console.log(newDates);
        if (newDates[0] && newDates[1]) {
          const rangeStart = newDates[0];
          const rangeEnd = newDates[1];
    
          setDateRange({
            start: startOfDay(rangeStart),
            end: endOfDay(rangeEnd),
          });
        }
      };

    const shortcutsItems: PickersShortcutsItem<DateRange<any>>[] = [
        {
          label: "Last 7 Days",
          getValue: () => {
            const today = new Date();
            const sevenDaysAgo = subDays(today, 7);
            return [sevenDaysAgo, today];
          },
        },
    
        {
          label: "Last 30 Days",
          getValue: () => {
            const today = new Date();
            const thirtyDays = subDays(today, 30);
            return [thirtyDays, today];
          },
        },
        {
          label: "Last 90 Days",
          getValue: () => {
            const today = new Date();
            const ninetyDays = subDays(today, 90);
            return [ninetyDays, today];
          },
        },
        { label: "Reset", getValue: () => [null, null] },
      ];

      const columns: GridColDef[] = [
        {
          field: "customer",
          headerName: "Customer",
          width: 200,
          valueGetter: (params) => params.row.customer.customerDisplayName,
        }, // Assuming customer has a 'name' property
        {
          field: "paymentDate",
          headerName: "Payment Date",
          width: 130,
          valueGetter: (params) => params.row.paymentDate.toDate(),
          valueFormatter: (params) => (params.value.toLocaleDateString ? params.value.toLocaleDateString() : params.value),

          renderCell: (params) => params.row.paymentDate.toDate().toLocaleDateString(),
        },
        {
          field: "total",
          headerName: "Total",
          valueGetter: (params) => fCurrency((params.row as Payment).total / 100),
          width: 120,
        },
        {
          field: "invoiceTax",
          headerName: "Invoice(s)",

          valueGetter: (params) => {
            const invoices = (params.row as Payment).invoices;
            return invoices;
          },
          valueFormatter: (params) => {
            const invoices = params.value as any[];
            const taxValues = invoices.map((invoice) => invoice.paymentApplicableTaxAmt / 100);

            return {
              value: taxValues,
              toString: () => {
                // Properly quote and format each value
                return taxValues
                  .map((tax) => {
                    const formatted = fCurrency(tax);
                    // Wrap in quotes to handle commas in currency
                    return `"${formatted}"`;
                  })
                  .join(",");
              },
            };
          },
          renderCell: (params) => {
            const invoices = (params.row as Payment).invoices;
            return (
              <Stack direction="column" spacing={1}>
                {invoices.map((invoice) => (
                  <div key={invoice.invoiceNumber}>
                    <MuiLink
                      component={Link}
                      to={`${ROUTE_EDIT_INVOICE}${invoice.invoiceId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ fontWeight: "bold" }}
                    >
                      #{invoice.invoiceNumber}
                    </MuiLink>
                  </div>
                ))}
              </Stack>
            );
          },
          width: 100,
        },
        {
          field: "taxes",
          headerName: "Taxes",

          valueGetter: (params) => {
            const invoices = (params.row as Payment).invoices;
            return invoices;
          },
          valueFormatter: (params) => {
            const invoices = params.value as any[];
            const taxValues = invoices.map((invoice) => invoice.paymentApplicableTaxAmt / 100);

            return {
              value: taxValues,
              toString: () => {
                // Properly quote and format each value
                return taxValues
                  .map((tax) => {
                    const formatted = fCurrency(tax);
                    // Wrap in quotes to handle commas in currency
                    return `"${formatted}"`;
                  })
                  .join(",");
              },
            };
          },
          renderCell: (params) => {
            const invoices = (params.row as Payment).invoices;
            return (
              <Stack direction="column" spacing={1}>
                {invoices.map((invoice) => (
                  <div key={invoice.invoiceNumber}>
                    {invoice.paymentApplicableTaxAmt && invoice.paymentApplicableTaxAmt > 0 ? (
                      <span>{fCurrency(invoice.paymentApplicableTaxAmt / 100)}</span>
                    ) : (
                      <span></span>
                    )}
                  </div>
                ))}
              </Stack>
            );
          },
          width: 300,
        },
      ];


  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="top"
      >
        <Grid item xs={12} md={6}>
          <div style={{ textAlign: "left", width: "100%" }}>
            <DateRangePicker
              value={[dateRange?.start, dateRange?.end]}
              onAccept={(newValue) => handleDateRangeChange(newValue)}
              slotProps={{
                textField: { size: "small" },
                shortcuts: {
                  items: shortcutsItems,
                },
                actionBar: {
                  actions: ["today"],
                },
              }}
            />
            <br />
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ width: "100%", p: 2 }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Box>
                <Stack direction="row" spacing={3} alignItems="left">
                  <Stack direction="column" spacing={0} alignItems="left">
                    <Typography variant="caption">Total Payments Received:</Typography>
                    <Typography variant="h6">{revenueTotalString}</Typography>
                  </Stack>

                  <Stack direction="column" spacing={0} alignItems="left">
                    <Typography variant="caption">Sales Tax:</Typography>
                    <Typography variant="h6">{taxTotalString}</Typography>
                  </Stack>

                  <Stack direction="column" spacing={0} alignItems="left">
                    <Typography variant="caption">Total Income:</Typography>
                    <Typography variant="h6">{incomeTotalString}</Typography>
                  </Stack>
                </Stack>

                <Typography variant="caption" fontWeight="bold">
                  {`Report from: ${
                    dateRange?.start
                      ? format(dateRange.start, "MM/dd/yyyy")
                      : ""
                  } - ${
                    dateRange?.end ? format(dateRange.end, "MM/dd/yyyy") : ""
                  }`}
                </Typography>
              </Box>
            </Box>
          </Card>
        </Grid>
      </Grid>
      <br />

      <Paper sx={{ p: 2 }}>
        <DataGridPro
          getRowHeight={() => 'auto'}
          rows={payments}
          columns={columns}
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          sx={{
            [`& .${gridClasses.cell}`]: {
              py: 1,
            },
          }}
        />
      </Paper>
      <br />
    </>
  );
}

export default RevenueReport