import { MutableRefObject, useCallback, useEffect, useRef, useState } from "react";

import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import {
  InventoryItem,
} from "../../../app/interfaces/inventoryItem";
import {
  DataGridPro,
  GridApiPro,
  GridColDef,
  GridValidRowModel,
  gridFilteredSortedRowEntriesSelector,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  InputAdornment,
  TextField,
  Tooltip,
  alpha,
  useTheme,
} from "@mui/material";
import Iconify from "../../../app/iconify";
import { useResponsiveFontSize } from "../../../app/hooks/use-responsive-font";
import { SelectedItem } from "../../../app/interfaces/SelectedItem";
import _ from 'lodash';

// ----------------------------------------------------------------------

type Props = {
  title?: string;
  systemOS: string;
  list: InventoryItem[];
  action?: React.ReactNode;
  //
  open: boolean;
  onClose: VoidFunction;
  //
  selected: (selectedId: string) => boolean;
  handleAddDiscount: VoidFunction;
  handleAddFlatFee: VoidFunction;
  handleAddDamageWaiver: VoidFunction;
  handleAddPercentageFee: VoidFunction;
  handleAddSubtotal: VoidFunction;
  handleAddLabelRow: VoidFunction;
  //
  createNewItem: VoidFunction;
  addAnItem: (item: InventoryItem, qty: number) => void;
  replaceAnItem: (newItem: InventoryItem, oldItem: SelectedItem, keepQuantity: boolean, qty: number) => void;
  itemToReplace: SelectedItem | null;

  onSelect: (item: InventoryItem | null) => void;
};

const debouncedSearch = _.debounce((query: string, apiRef: MutableRefObject<GridApiPro>) => {
  if (apiRef.current) {
    apiRef.current.setQuickFilterValues([query]);
  }
}, 300);

export default function InventoryPickerDialog({
  title = "Address Book",
  systemOS,
  list,
  action,
  //
  open,
  onClose,
  //
  selected,
  handleAddDiscount,
  handleAddFlatFee,
  handleAddDamageWaiver,
  handleAddPercentageFee,
  handleAddSubtotal,
  handleAddLabelRow,

  createNewItem,
  addAnItem,
  replaceAnItem,
  itemToReplace,

  onSelect,
}: Props) {
  const theme = useTheme();
  const responsiveFontSize = useResponsiveFontSize();

  const apiRef = useGridApiRef();
  const [searchQuery, setSearchQuery] = useState("");
  const responseiveFontSize = useResponsiveFontSize();

  const [isNewItem] = useState(!itemToReplace);
  const [keepQuantity, setKeepQuantity] = useState(true);
  const [quantity, setQuantity] = useState(1);
  const quantityRef = useRef(quantity);
  useEffect(() => {
    quantityRef.current = quantity;
  }, [quantity]);

  const handleSelectInventoryItem = useCallback((item: InventoryItem | null, qty: number) => {
      if (isNewItem && item) {
        addAnItem(item, qty);
      } else if (item && itemToReplace) {
        replaceAnItem(item, itemToReplace, keepQuantity, qty);
      }
      searchQuery && setSearchQuery("");
      onClose();
    },
    [isNewItem, itemToReplace, searchQuery, onClose, addAnItem, replaceAnItem, keepQuantity]
  );

  const columns: GridColDef<(typeof list)[number]>[] = [
    {
      field: "name",
      headerName: "Item",
      width: 500,
      editable: false,
      resizable: false,
      filterable: false,
      sortable: false,
      pinnable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const filteredSortedRowsAndIds =
          gridFilteredSortedRowEntriesSelector(apiRef);
        const rowIndex = params.api.getRowIndexRelativeToVisibleRows(params.id);

        if (filteredSortedRowsAndIds && filteredSortedRowsAndIds.length === 1) {
          return (
            <Stack direction="row" spacing={1} justifyContent={"space-between"} width="100%">
              {" "}
              <Typography variant="body2">{params.row.name}</Typography>
              <Chip label="Enter &crarr;" size="small" sx={{ mr: 10 }} />
            </Stack>
          );
        } else if (rowIndex < 9) {
          if (rowIndex === 0) {
            return (
              <Stack direction="row" spacing={1} justifyContent={"space-between"} width="100%">
                <Stack direction="row" spacing={1}>
                  <Tooltip title={`${systemOS === "mac" ? `⌘ ${rowIndex + 1}` : `ctrl ${rowIndex + 1}`}`} arrow>
                    <Chip label={rowIndex + 1} size="small" />
                  </Tooltip>
                  <Typography variant="body2">{params.row.name}</Typography>
                </Stack>
                <Chip label="Enter &crarr;" size="small" sx={{ mr: 10 }} />
              </Stack>
            );
          } else {
            return (
              <Stack direction="row" alignItems="center" spacing={1}>
                  <Tooltip title={`${systemOS === "mac" ? `⌘ ${rowIndex + 1}` : `ctrl ${rowIndex + 1}`}`} arrow>
                  <Chip label={rowIndex + 1} size="small" />
                </Tooltip>
                <Typography variant="body2">{params.row.name}</Typography>
              </Stack>
            );
          }
        } else {
          return (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="body2">{params.row.name}</Typography>
            </Stack>
          );
        }
      },
    },
  ];

  useEffect(() => {
    const handleCommandEnter = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        event.preventDefault();

        const filteredSortedRowsAndIds =
          gridFilteredSortedRowEntriesSelector(apiRef);

        handleSelectInventoryItem(
          filteredSortedRowsAndIds[0].model as InventoryItem, quantityRef.current
        );
      } else if (event.key >= "1" && event.key <= "9" && (event.metaKey || event.ctrlKey)) {

        event.preventDefault();

        const index = parseInt(event.key, 10) - 1;
        const filteredSortedRowsAndIds = gridFilteredSortedRowEntriesSelector(
          apiRef.current.state
        );

        if (
          filteredSortedRowsAndIds &&
          index < filteredSortedRowsAndIds.length
        ) {
          handleSelectInventoryItem(
            filteredSortedRowsAndIds[index].model as InventoryItem, quantityRef.current
          );
        }
      }
    };

    if (open) {
      window.addEventListener("keydown", handleCommandEnter);
    }
    return () => {
      window.removeEventListener("keydown", handleCommandEnter);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  // const handleSearchQuery = useCallback(
  //   (event: React.ChangeEvent<HTMLInputElement>) => {
  //     setSearchQuery(event.target.value);
  //     apiRef.current?.setQuickFilterValues([event.target.value]);
  //   },
  //   [apiRef]
  // );

  // const debouncedSearch = useCallback(
  //   _.debounce((query: string) => {
  //     if (apiRef.current) {
  //       apiRef.current.setQuickFilterValues([query]);
  //     }
  //   }, 300), // Adjust the debounce delay as needed
  //   [apiRef]
  // );

  const handleSearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);
    debouncedSearch(query, apiRef);
  };


  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      {!isNewItem && (
        <Box bgcolor={alpha(theme.palette.warning.lighter, 0.8)} borderRadius={2} padding={2} margin={1}>
          <Typography>{`You are replacing "${itemToReplace?.name}" with a new item you select below.`}</Typography>

          <FormControlLabel
            sx={{ float: "right" }}
            control={
              <Checkbox
                inputProps={{ style: { fontSize: responsiveFontSize } }}
                checked={keepQuantity}
                onChange={(e) => {
                  setKeepQuantity(e.target.checked);
                }}
              />
            }
            label={<Typography fontSize={responsiveFontSize}>{`Keep Quantity (${itemToReplace?.selectedQty})`}</Typography>}
          />
        </Box>
      )}

      {isNewItem && (
        <Stack direction={"row"} gap={1} sx={{ p: 1 }}>
          <Button
            size="small"
            variant="outlined"
            onClick={handleAddSubtotal}
            startIcon={
              <Iconify
                icon="fluent:add-12-regular"
                width={16}
                sx={{
                  paddingRight: "0px",
                  marginRight: "0px",
                }}
              />
            }
            sx={{
              fontSize: responseiveFontSize - 2,
            }}
          >{`Subtotal`}</Button>

          {/* <Button
            size="small"
            variant="outlined"
            onClick={handleAddLabelRow}
            startIcon={
              <Iconify
                icon="fluent:add-12-regular"
                width={16}
                sx={{
                  paddingRight: "0px",
                  marginRight: "0px",
                }}
              />
            }
            sx={{
              fontSize: responseiveFontSize - 2,
            }}
          >{`Label`}</Button>

          <Button
            size="small"
            variant="outlined"
            onClick={handleAddDamageWaiver}
            startIcon={
              <Iconify
                icon="fluent:add-12-regular"
                width={16}
                sx={{
                  paddingRight: "0px",
                  marginRight: "0px",
                }}
              />
            }
            sx={{
              fontSize: responseiveFontSize - 2,
            }}
          >{`Damage Waiver`}</Button>

          <Button
            size="small"
            variant="outlined"
            onClick={handleAddPercentageFee}
            startIcon={
              <Iconify
                icon="fluent:add-12-regular"
                width={16}
                sx={{
                  paddingRight: "0px",
                  marginRight: "0px",
                }}
              />
            }
            sx={{
              fontSize: responseiveFontSize - 2,
            }}
          >{`Percent Fee`}</Button>

          <Button
            size="small"
            variant="outlined"
            onClick={handleAddFlatFee}
            startIcon={
              <Iconify
                icon="fluent:add-12-regular"
                width={16}
                sx={{
                  paddingRight: "0px",
                  marginRight: "0px",
                }}
              />
            }
            sx={{
              fontSize: responseiveFontSize - 2,
            }}
          >{`Flat Fee`}</Button> */}

            
          {/* <Button
            size="small"
            variant="outlined"
            onClick={handleAddDiscount}
            startIcon={
              <Iconify
                icon="fluent:add-12-regular"
                width={16}
                sx={{
                  paddingRight: "0px",
                  marginRight: "0px",
                }}
              />
            }
            sx={{
              fontSize: responseiveFontSize - 2,
            }}
          >{`Discount`}</Button> */}
        </Stack>
      )}

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 2, pr: 1.5 }}>
        <Typography variant="h6"> {title} </Typography>

        {action && isNewItem && action}
      </Stack>

      <Stack direction={"row"}>
        <TextField
          size="small"
          sx={{ mx: 2 }}
          value={searchQuery}
          onChange={handleSearchQuery}
          placeholder="Search..."
          autoFocus={true}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: "text.disabled" }} />
              </InputAdornment>
            ),
          }}
        />

        <TextField
          size="small"
          label="Quantity"
          type="number"
          sx={{ mx: 2, width: "150px" }}
          value={quantity}
          onChange={(e) => setQuantity(parseInt(e.target.value))}
        />
      </Stack>

      {open && (
        <DataGridPro
          apiRef={apiRef}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          columns={columns}
          rows={list}
          loading={list.length === 0}
          rowHeight={38}
          onRowClick={(row) => handleSelectInventoryItem(row.row as InventoryItem, quantity)}
          disableRowSelectionOnClick
          sx={{
            minHeight: "400px",
            p: 1,
            borderTopWidth: 0,
            "& .MuiDataGrid-row": {
              cursor: "pointer",
            },
          }}
        />
      )}
    </Dialog>
  );
}
