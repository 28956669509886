import { useCallback, useEffect, useState } from "react";

import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";

import {
  DataGridPro,
  GridColDef,
  gridFilteredSortedRowEntriesSelector,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import {
  Box,
  Chip,
  InputAdornment,
  TextField,
  alpha,
  useTheme,
} from "@mui/material";
import Iconify from "../../../app/iconify";
import { useCurrentBreakpoint, useResponsiveFontSize } from "../../../app/hooks/use-responsive-font";
import { Customer } from "../../../app/interfaces/customer";
import { useCustomerSearch } from "../../../app/services/hooks/fetch/firestoreSearch/useCustomerSearch";

// ----------------------------------------------------------------------

type Props = {
  title?: string;
  systemOS: string;
  action?: React.ReactNode;
  //
  open: boolean;
  onClose: VoidFunction;
  //
  createNewCustomer: VoidFunction;
  addCustomer: (customer: Customer) => void;
  replaceCustomer: (newCustomer: Customer, oldCustomer: Customer) => void;
  customerToReplace: Customer | null;
};

export default function CustomerPickerDialog({
  title = "Address Book",
  systemOS,
  action,
  //
  open,
  onClose,
  createNewCustomer,
  addCustomer,
  replaceCustomer,
  customerToReplace,
}: Props) {

  const theme = useTheme();
  const responsiveFontSize = useResponsiveFontSize();

  const Customers = useCustomerSearch();

  useEffect(() => {
    Customers.actions.loadDefault();
  }, []);

  // useEffect(() => {

  //   if (!currentId) return; // new invoice
  //   // generate text for invoice since its uneditable
  //   if (currentInvoice.type === INVOICE)
  //     return Customers.actions.insertRecord({
  //       id: currentInvoice.customerQueryHook,
  //       customerDisplayName: currentInvoice.customer.customerDisplayName,
  //     });
  //   // invoice or estimate. fetch customer and load customers
  //   customerQueries({ firestore }, fsOrgPrefix)
  //     .fetchCustomer(currentInvoice.customerQueryHook)
  //     .then((res) => {
  //       Customers.actions.insertRecord(res);
  //     });
  //   Customers.actions.refreshDefault();
  // }, [currentId]);

  const apiRef = useGridApiRef();
  const [searchQuery, setSearchQuery] = useState("");
  const responseiveFontSize = useResponsiveFontSize();

  const { isMDAndUp, isMDAndDown, isLGAndUp } = useCurrentBreakpoint();
  const [isNewItem] = useState(!customerToReplace);

  const handleSelectedRow = useCallback((customer: Customer | null) => {
      if (isNewItem && customer) {
        addCustomer(customer);
      } else if (customer && customerToReplace) {
        replaceCustomer(customer, customerToReplace);
      }
      searchQuery && setSearchQuery("");
      onClose();
    },
    [onClose]
  );

  const columns: GridColDef<Customer>[] = [
    {
      field: "customerDisplayName",
      headerName: "Customer",
      width: 500,
      editable: false,
      resizable: false,
      filterable: false,
      sortable: false,
      pinnable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
          return (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="body2">{params.row.customerDisplayName}</Typography>
            </Stack>
          );
      },
    },
  ];


  const handleSearchQuery = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const searchQuery = event.target.value;
      setSearchQuery(searchQuery);
      Customers.actions.onSearch(searchQuery);
    },
    []
  );

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>

      {
        !isNewItem && (
          <Box bgcolor={alpha(theme.palette.warning.lighter, 0.8)} borderRadius={2} padding={2} margin={1}>
            <Typography>
              {`You are replacing "${customerToReplace?.customerDisplayName}" with a new Customer you select below. This may change the receive and return addresses so be sure to double check the details.`}
            </Typography>
          </Box>
        )
      }

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ p: 2, pr: 1.5 }}
      >
        <Typography variant="h6"> {title} </Typography>

        {action && action}

      </Stack>

      <TextField
        sx={{ mx: 2 }}
        value={searchQuery}
        onChange={handleSearchQuery}
        placeholder="Search..."
        autoFocus={true}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: "text.disabled" }} />
            </InputAdornment>
          ),
        }}
      />

      {open && Customers.state.data.length > 0 ? (
        <DataGridPro
          apiRef={apiRef}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          columns={columns}
          rows={Customers.state.data}
          loading={Customers.state.data.length === 0}
          rowHeight={38}
          onRowClick={(row) =>
            handleSelectedRow(row.row as Customer)
          }
          disableRowSelectionOnClick
          sx={{
            minHeight: "400px",
            p: 1,
            borderTopWidth: 0,
            "& .MuiDataGrid-row": {
              cursor: "pointer",
            },
          }}
        />
      ): (
        <Typography sx={{ p: 2 }} variant="body1">
          Loading...
        </Typography>
      )}
    </Dialog>
  );
}
